import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  GET_PREMINT_ASSEST,
  GET_PREMINT_ASSEST_COUNT,
  CREATE_PREMINT_ASSEST,
  UPDATE_PREMINT_ASSEST,
  UPDATE_PREMINT_ASSEST_SHOW_AND_HIDE,
  UPLOAD_PREMINT_IMAGE,
  CREATE_PREMINT_ASSET_FROM_JSON,
  REGISTER_COLLECTION_SMART_CONTRACT,
  GET_COLLECTION_SMART_CONTRACT_BY_ID,
  UPDATE_COLLECTION_SMART_CONTRACT,
  AIRDROP_COLLECTION_SMART_CONTRACT,

  // manual
  CREATE_MANUAL_PREMINT_ASSET,
  UPDATE_MANUAL_PREMINT_ASSET,
  PUBLISH_MANUAL_PREMINT_ASSETS,

// SOLANA 
  CREATE_SOLANA_PREMINT_ASSET,
  GET_SOLANA_PREMINT_ASSET,
  UPLOAD_SOLANA_IMGAE_TO_AWS
} from '../actions';

import {
  setPremintCountDetails,
  setUploadPreiminImage,
  setFulfilledPremintAssestTableData,
  setFulfilledupdatePremitnAssetShowAndHide,
  setFulfilledCreatePremitnAsset,
  setFulfilledCollectionSmartContractById,
  setContractSuccessState,
  setRejectUploadImage,
  setPremintPending,
  setCreateAssetFromJsonsSuccess,
  // <============common============>
  setPending,
  setFulfilled,
  // setFulfilledWithValue,
  setRejected,
  reset,
  // <===============munual=================>
  // setFulfilledPublishManualPremintAssest,
  // setRejectedPublishManualPremintAssest,
  setManualPremintAssetSucces,

  // <------------solana------------------>
  setSolanaPremintAssest,
  setSolanaUploadPreiminImage,
} from './aciotns';

import {
  getPremintAssest,
  getPremintAssestCount,
  createPremintAssest,
  updatePremintAssest,
  updateShowHidePremintAssetAPI,
  uploadPremintAssestImageAPI,
  uploadPremintAssetJsonAPI,

  // <==========smartContract==========>
  registerCollectionSmartContractAPI,
  getCollectionSmartContractByIdAPI,
  getUpdateCollectionSmartContractAPI,
  submitAirdropCollectionSmartContractAPI,

// <============manual=================>
  createManualPremintAssestAPI,
  udateManualPremintAssestAPI,
  publishManualPremintAssestAPI,

  // SOLANA
  createSolanaPremintAssestAPI,
  getSolanaPremintAssestAPI,
  uploadSolanaPremintAssestImageAPI,
  
} from './getAssests';
import JwtExpired from '../../utils/JwtExpired';

export function* getTableData(action) {
  try {
    // yield put(setPending());
    yield put(setPremintPending());
    const data = yield call(getPremintAssest, action.payload.data);
    // const { data } = response;
    yield put(setFulfilledPremintAssestTableData(data));
    // yield put(reset());
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

export function* getAssetCountData() {
  try {
    const response = yield call(getPremintAssestCount);
    const { data } = response;
    yield put(setPremintCountDetails(data.count));
  } catch (error) {
    //   yield put(getSurveyDetailError(error));
    console.log(error);
  }
}

export function* uploadPremintImageData(action) {
  try {
    yield put(setUploadPreiminImage({ image: null, imgLoading: true }));
    const response = yield call(uploadPremintAssestImageAPI, action.payload.data);
    // console.log(response.data.message.hash);
    yield put(
      setUploadPreiminImage({
        image: response?.message?.hash,
        thumbnail: response?.message?.thumbnail,
        imgLoading: false,
      })
    );
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejectUploadImage({image: null, imgLoading: false, message }))
    JwtExpired(message, action.payload.history)
  }
}

export function* createAssest(action) {
  try {
    yield put(setPending());
    const res = yield call(createPremintAssest, action.payload.data);
    yield put(setFulfilledCreatePremitnAsset({ message: res.message }));
    // yield put(setFulfilledWithValue({ message: res.message, data: null }));
    // yield put(clearField());
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

export function* createAssetFromJson(action) {
  // console.log(action)
  try {
    yield put(setPending());
    const res = yield call(uploadPremintAssetJsonAPI, action.payload.formData);
    // yield put(setFulfilled());
    yield put(setCreateAssetFromJsonsSuccess({ message: res.message }));
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

export function* updateAssest(action) {
  try {
    yield put(setPending());
    const res = yield call(updatePremintAssest, action.payload.data);
    yield put(setFulfilledCreatePremitnAsset({ message: res.message }));
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

export function* updatePremintAssetShowHide(action) {
  try {
    yield put(setPending());
    const res = yield call(updateShowHidePremintAssetAPI, action.payload.data);
    yield put(setFulfilledupdatePremitnAssetShowAndHide({ message: res.message }));
    // yield put(setState({ status: null, isLoading: false, error: null }));
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

export function* registerCollectionSmartContractSaga(action) {
  // console.log(action);
  try {
    yield put(setPending());
    const res = yield call(registerCollectionSmartContractAPI, action.payload.formData);
    yield put(setContractSuccessState({ message: res.message }));
    // yield put(clearField());
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

export function* getCollectionSmartContractByIdSaga(action) {
  // console.log(action);
  try {
    yield put(setPending());
    const res = yield call(getCollectionSmartContractByIdAPI, action.payload.data);
    //  console.log(res.data.data)
    yield put(setFulfilledCollectionSmartContractById(res?.data));
    // yield put(reset());
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

export function* updateCollectionSmartContractSaga(action) {
  // console.log(action);
  try {
    yield put(setPending());
    const res = yield call(getUpdateCollectionSmartContractAPI, action.payload.formData);
    //  console.log(res.data.data)
    yield put(setContractSuccessState({ message: res.message }));
    // yield put(reset());
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )  }
}

export function* SubmitAirdropCollectionSmartContractSaga(action) {
  try {
    yield put(setPending());
    yield call(
      submitAirdropCollectionSmartContractAPI,
      action.payload
    );
    // const result = res?.data?.mintTransactionHash
    //  console.log(res)
    yield put(setFulfilled());
    // yield put(reset());
  } catch (error) {
    // console.log(error)
    yield put(setRejected({ message: error.response.data.error }));
  }
}

// <================================Muanal premint ========================================>

export function* createManualPremintAssestSaga(action) {
  try {
    yield put(setPending());
    const res = yield call(createManualPremintAssestAPI, action.payload.data);
    // console.log(res)
    yield put(setManualPremintAssetSucces({ message: res.message }));
    // yield put(setFulfilled());
    // yield put(setFulfilledCreatePremitnAsset());
    // yield put(clearField());
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )  }
}

export function* updateManualPremintAssestSaga(action) {
  try {
    yield put(setPending());
    const res = yield call(udateManualPremintAssestAPI, action.payload.data);
    // console.log(res)

    yield put(setManualPremintAssetSucces({ message: res.message }));

    // yield put(setFulfilled());
    // yield put(setFulfilledCreatePremitnAsset());
    // yield put(clearField());
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )  }
}

export function* publishManualPremintAssestSaga(action) {
  try {
    yield put(setPending());
    const res = yield call(publishManualPremintAssestAPI, action.payload.collection_id);
    yield put(setManualPremintAssetSucces({ message: res.message }));
    // yield put(setFulfilledPublishManualPremintAssest());
    // yield put(setFulfilledCreatePremitnAsset());
    // yield put(clearField());
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history ) 
   } 
}


// <----------------------------SOLANA--------------------------->

export function* createSolanaPremintAssestSaga(action) {
  try {
    yield put(setPending());
    yield call(createSolanaPremintAssestAPI, action.payload);
    yield put(setFulfilled());
    // yield put(setFulfilledCreatePremitnAsset());
    // yield put(clearField());
  } catch (error) {
    if (
      error.response.data.error ===
      'collectionName Already Exist Please Provide Different collectionName. In Case You have Not Provided collectionName Then Provide Different Asset Name'
    ) {
      yield put(setRejected({ message: 'Collection Name Already Exist!' }));
      return
    }
    yield put(setRejected({ message: error.response.data.error }));
  }
}

export function* getSolanaTableDataSaga(action) {
  try {
    yield put(setPending());
    const response = yield call(getSolanaPremintAssestAPI, action.payload);
    const { data } = response;
    yield put(setSolanaPremintAssest(data?.solanaPremintAssets));
    yield put(reset());
  } catch (error) {
    yield put(setRejected({message: 'failed'}));
  }
}

export function* uploadSolanaPremintImageDataSaga(action) {
  console.log(action)
  try {
    yield put(setSolanaUploadPreiminImage({ image: null, imgLoading: true }));
    const response = yield call(uploadSolanaPremintAssestImageAPI, action.payload.data);
    // console.log(response.data.message.hash);
    yield put(
      setSolanaUploadPreiminImage({
        image: response?.data.image,
        imgLoading: false,
      })
    );
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejectUploadImage({image: null, imgLoading: false, message }))
    JwtExpired(message, action.payload.history)
  }
}
// export function* uploadSolanaPremintImageDataSaga(action) {
//   console.log(action)
//   try {
//     yield put(setSolanaUploadPreiminImage({ image: null, imgLoading: true }));
//     const response = yield call(uploadSolanaPremintAssestImageAPI, action.payload);
//     // console.log(response.data.message.hash);
//     yield put(
//       setSolanaUploadPreiminImage({
//         image: response?.data.image,
//         imgLoading: false,
//       })
//     );
//   } catch (error) {
//     yield put(setSolanaUploadPreiminImage({ image: null, imgLoading: false }));
//   }
// }


export function* watchGetAssestsItem() {
  yield takeEvery(GET_PREMINT_ASSEST, getTableData);
}

export function* watchGetAssestsCount() {
  yield takeEvery(GET_PREMINT_ASSEST_COUNT, getAssetCountData);
}

export function* watchuploadPremintImageData() {
  yield takeLatest(UPLOAD_PREMINT_IMAGE, uploadPremintImageData);
}
export function* watchCreateAssest() {
  yield takeEvery(CREATE_PREMINT_ASSEST, createAssest);
}
export function* watchCreateAssestFromJson() {
  yield takeEvery(CREATE_PREMINT_ASSET_FROM_JSON, createAssetFromJson);
}
export function* watchUpdateAssest() {
  yield takeEvery(UPDATE_PREMINT_ASSEST, updateAssest);
}
export function* watchCollectionSmartContractByID() {
  yield takeEvery(
    REGISTER_COLLECTION_SMART_CONTRACT,
    registerCollectionSmartContractSaga
  );
}
export function* watchregisterCollectionSmartContract() {
  yield takeEvery(
    GET_COLLECTION_SMART_CONTRACT_BY_ID,
    getCollectionSmartContractByIdSaga
  );
}
export function* watchUpdateCollectionSmartContract() {
  yield takeEvery(
    UPDATE_COLLECTION_SMART_CONTRACT,
    updateCollectionSmartContractSaga
  );
}
export function* watchAirdropCollectionSmartContract() {
  yield takeEvery(
    AIRDROP_COLLECTION_SMART_CONTRACT,
    SubmitAirdropCollectionSmartContractSaga
  );
}
export function* watchUpdateAssestShowAndHide() {
  yield takeEvery(
    UPDATE_PREMINT_ASSEST_SHOW_AND_HIDE,
    updatePremintAssetShowHide
  );
}

// <=================muanal=======================>
export function* watchCreateManualPremint() {
  yield takeEvery(
    CREATE_MANUAL_PREMINT_ASSET,
    createManualPremintAssestSaga
  );
}
export function* watchUpdateManualPremint() {
  yield takeEvery(
    UPDATE_MANUAL_PREMINT_ASSET,
    updateManualPremintAssestSaga
  );
}
export function* watchPublishManualPremint() {
  yield takeEvery(
    PUBLISH_MANUAL_PREMINT_ASSETS,
    publishManualPremintAssestSaga
  );
}


// <--------------------------solana------------------------->

export function* watchCreateSolanaPremint() {
  yield takeEvery(
    CREATE_SOLANA_PREMINT_ASSET,
    createSolanaPremintAssestSaga
  );
}
export function* watchGetSolanaPremint() {
  yield takeEvery(
    GET_SOLANA_PREMINT_ASSET,
    getSolanaTableDataSaga
  );
}
export function* watchUploadSolanaPremintImageToAws() {
  yield takeEvery(
    UPLOAD_SOLANA_IMGAE_TO_AWS,
    uploadSolanaPremintImageDataSaga
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAssestsItem),
    fork(watchGetAssestsCount),
    fork(watchuploadPremintImageData),
    fork(watchCreateAssest),
    fork(watchCreateAssestFromJson),
    fork(watchUpdateAssest),
    fork(watchregisterCollectionSmartContract),
    fork(watchCollectionSmartContractByID),
    fork(watchUpdateCollectionSmartContract),
    fork(watchAirdropCollectionSmartContract),
    fork(watchUpdateAssestShowAndHide),

    // manual
    fork(watchCreateManualPremint),
    fork(watchUpdateManualPremint),
    fork(watchPublishManualPremint),

    // solana
    fork(watchCreateSolanaPremint),
    fork(watchGetSolanaPremint),
    fork(watchUploadSolanaPremintImageToAws)
  ]);
}
