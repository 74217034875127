import { all } from 'redux-saga/effects';
// import authSagas from './auth/saga';
//  import todoSagas from './todo/saga';
// import chatSagas from './chat/saga';
// import surveyListSagas from './surveyList/saga';
// import surveyDetailSagas from './surveyDetail/saga';
import premintSaga from "./PremintAssests/saga"
import collectionSaga from "./collection/saga"
import paymentSaga from "./payment/sagas"
import auth from "./adminAuth/saga"
import SettingSaga from "./adminSetting/Saga"
import Profile from "./profile/saga"
import generalPremintAssetSaga from "./GeneralPremintAssest/saga"
import dashboard from "./dashboard/saga"

export default function* rootSaga(getState) {
  yield all([
    // authSagas(),
    //  todoSagas(),
    // chatSagas(),
    // surveyListSagas(),
    // surveyDetailSagas(),
    premintSaga(),
    generalPremintAssetSaga(),
    collectionSaga(),
    paymentSaga(),
    auth(),
    SettingSaga(),
    Profile(),
    dashboard()
  ]);
}
