import {
  GET_PREMINT_ASSEST,
  CREATE_PREMINT_ASSEST,
  UPDATE_PREMINT_ASSEST,
  UPDATE_COLLECTION,
  GET_PREMINT_ASSEST_COUNT,
  SET_PREMINT_ASSEST_COUNT,
  UPDATE_PREMINT_ASSEST_SHOW_AND_HIDE,
  UPLOAD_PREMINT_IMAGE,
  SET_UPLOAD_PREMINT_IMAGE,
  SET_FULFILLED_GET_TABLE_DATA,
  SET_FULFILLED_SHOW_HIDE_PREMINT_ASSET,
  SET_FULFILLED_CREATE_PREMINT_ASSET,
  CREATE_PREMINT_ASSET_FROM_JSON,
  REGISTER_COLLECTION_SMART_CONTRACT,
  GET_COLLECTION_SMART_CONTRACT_BY_ID,
  SET_FULFILLED_GET_COLLECTION_SMART_CONTRACT_BY_ID,
  UPDATE_COLLECTION_SMART_CONTRACT,
  AIRDROP_COLLECTION_SMART_CONTRACT,
  SET_CONTRACT_SUCCESS_STATE,
  SMART_CONTRACT_PENDING_STATE,
  SET_CONTRACT_SUCCESS_STATE_FOR_AIRDROP_AND_MERKLE_ROOT,
  SET_CHECK,
  SET_REJECT_UPLOAD_IMAGE,
  SET_PREMINT_MODAL_VISIBLE,
  SET_PREMINT_DRAWER_VISIBLE,
  SET_PREMINT_PENDING,
  SET_CREATE_ASSEST_JSON_SUCCESS,
  PREMINT_PAGINATION,
  // <=========================COMMON==========================>
  // SET_DRAWER_VISIBLE,
  // SET_MODAL_VISIBLE,
  SET_NESTING_MODAL_VISIBLE,
  SET_PENDING,
  SET_FULFILLED,
  SET_FULFILLED_WITH_VALUE,
  SET_REJECTED,
  RESET_STATE,
  SET_DATA,
  // <=================MANUAL============================>
  CREATE_MANUAL_PREMINT_ASSET,
  UPDATE_MANUAL_PREMINT_ASSET,
  PUBLISH_MANUAL_PREMINT_ASSETS,
  FULFILLED_PUBLISH_MANUAL_PREMINT_ASSETS,
  REJECTED_PUBLISH_MANUAL_PREMINT_ASSETS,
  SET_MANUAL_PREMINT_ASSEST_SUCCES,
  // <----------------------solana----------------------->
  CREATE_SOLANA_PREMINT_ASSET,
  GET_SOLANA_PREMINT_ASSET,
  SET_SOLANA_PREMINT_ASSET,
  UPLOAD_SOLANA_IMGAE_TO_AWS,
  SET_SOLANA_IMGAE_TO_AWS,
} from '../actions';

export const getPremintDetails = (data) => ({
  type: GET_PREMINT_ASSEST,
  payload: data,
});

export const uploadPreimintImage = (data) => ({
  type: UPLOAD_PREMINT_IMAGE,
  payload: data,
});
export const setUploadPreiminImage = (data) => ({
  type: SET_UPLOAD_PREMINT_IMAGE,
  payload: data,
});

export const setRejectUploadImage = (data) => ({
  type: SET_REJECT_UPLOAD_IMAGE,
  payload: data,
});

export const createPremintAssestDetials = (data) => ({
  type: CREATE_PREMINT_ASSEST,
  payload: data,
});

export const createPremintAssetFromJson = (data) => ({
  type: CREATE_PREMINT_ASSET_FROM_JSON,
  payload: data,
});
export const updatePremintAssestDetials = (data) => ({
  type: UPDATE_PREMINT_ASSEST,
  payload: data,
});

export const updatePremitnAssetShowAndHide = (data) => ({
  type: UPDATE_PREMINT_ASSEST_SHOW_AND_HIDE,
  payload: data,
});

export const updateCollection = (data) => ({
  type: UPDATE_COLLECTION,
  payload: data,
});

export const getPremintCountDetails = () => ({
  type: GET_PREMINT_ASSEST_COUNT,
});

export const setPremintCountDetails = (data) => ({
  type: SET_PREMINT_ASSEST_COUNT,
  payload: data,
});

export const setCheck = (data) => ({
  type: SET_CHECK,
  payload: data,
});
export const setFulfilledCreatePremitnAsset = (data) => ({
  type: SET_FULFILLED_CREATE_PREMINT_ASSET,
  payload: data
});
export const setFulfilledupdatePremitnAssetShowAndHide = (data) => ({
  type: SET_FULFILLED_SHOW_HIDE_PREMINT_ASSET,
  payload: data,
});

export const setFulfilledPremintAssestTableData = (data) => ({
  type: SET_FULFILLED_GET_TABLE_DATA,
  payload: data,
});

export const setPremintPending = (data) => ({
  type: SET_PREMINT_PENDING,
  payload: data,
});

export const setCreateAssetFromJsonsSuccess = (data) => ({
  type: SET_CREATE_ASSEST_JSON_SUCCESS,
  payload: data,
});

export const premintPagination = data => ({
  type: PREMINT_PAGINATION,
  payload: data,
});


// <==================================smartContract========================>
export const registerCollectionSmartContract = (data) => ({
  type: REGISTER_COLLECTION_SMART_CONTRACT,
  payload: data,
});
export const getCollectionSmartContractById = (data) => ({
  type: GET_COLLECTION_SMART_CONTRACT_BY_ID,
  payload: data,
});
export const setFulfilledCollectionSmartContractById = (data) => ({
  type: SET_FULFILLED_GET_COLLECTION_SMART_CONTRACT_BY_ID,
  payload: data,
});
export const updateCollectionSmartContract = (data) => ({
  type: UPDATE_COLLECTION_SMART_CONTRACT,
  payload: data,
});
export const SubmitAirdropCollectionSmartContract = (data) => ({
  type: AIRDROP_COLLECTION_SMART_CONTRACT,
  payload: data,
});
export const setContractSuccessState = (data) => ({
  type: SET_CONTRACT_SUCCESS_STATE,
  payload: data,
});
export const setPendingStateForSmartContract = (data) => ({
  type: SMART_CONTRACT_PENDING_STATE,
  payload: data,
});
export const setContractSuccessStateforAirdropAndMerkle = (data) => ({
  type: SET_CONTRACT_SUCCESS_STATE_FOR_AIRDROP_AND_MERKLE_ROOT,
  payload: data,
});

// <=========================Manual==================================>
export const createManualPremintAssest = (data) => ({
  type: CREATE_MANUAL_PREMINT_ASSET,
  payload: data,
});
export const updateManualPremintAssest = (data) => ({
  type: UPDATE_MANUAL_PREMINT_ASSET,
  payload: data,
});
export const publishManualPremintAssest = (data) => ({
  type: PUBLISH_MANUAL_PREMINT_ASSETS,
  payload: data,
});

export const setFulfilledPublishManualPremintAssest = (data) => ({
  type: FULFILLED_PUBLISH_MANUAL_PREMINT_ASSETS,
  payload: data,
});
export const setRejectedPublishManualPremintAssest = (data) => ({
  type: REJECTED_PUBLISH_MANUAL_PREMINT_ASSETS,
  payload: data,
});
export const setManualPremintAssetSucces = (data) => ({
  type: SET_MANUAL_PREMINT_ASSEST_SUCCES,
  payload: data,
});


// <-------------------------------solana--------------------------->

export const createSolanaPremintAssest = (data) => ({
  type: CREATE_SOLANA_PREMINT_ASSET,
  payload: data,
});

export const getSolanaPremintAssest = (data) => ({
  type: GET_SOLANA_PREMINT_ASSET,
  payload: data,
});

export const setSolanaPremintAssest = (data) => ({
  type: SET_SOLANA_PREMINT_ASSET,
  payload: data,
});

export const uploadSolanaPreimintImage = (data) => ({
  type: UPLOAD_SOLANA_IMGAE_TO_AWS,
  payload: data,
});

export const setSolanaUploadPreiminImage = (data) => ({
  type: SET_SOLANA_IMGAE_TO_AWS,
  payload: data,
});

export const setModalVisible = (data) => ({
  type: SET_PREMINT_MODAL_VISIBLE,
  payload: data,
});

export const setDrawerVisible = (data) => ({
  type: SET_PREMINT_DRAWER_VISIBLE,
  payload: data,
});



//<======================== common ==============================>

export const setPending = () => ({
  type: SET_PENDING,
});
export const setFulfilled = () => ({
  type: SET_FULFILLED,
});
export const setFulfilledWithValue = data => ({
  type: SET_FULFILLED_WITH_VALUE,
  payload: data
})
export const setRejected = (data) => ({
  type: SET_REJECTED,
  payload: data,
});
export const setData = (data) => ({
  type: SET_DATA,
  payload: data,
});

// export const setModalVisible = (data) => ({
//   type: SET_MODAL_VISIBLE,
//   payload: data,
// });
// export const setDrawerVisible = (data) => ({
//   type: SET_DRAWER_VISIBLE,
//   payload: data,
// });
export const setNestingModalVisible = (data) => ({
  type: SET_NESTING_MODAL_VISIBLE,
  payload: data,
});
export const reset = () => ({
  type: RESET_STATE,
});
