import {
  SET_ASSET_AND_COLLECTION_COUNT,
  // <=========================COMMON==========================>
  SET_PENDING_DASHBOARD,
  SET_REJECTED_DASHBOARD,
  RESET_STATE_DASHBOARD,
  SET_ALL_IP_INFO,
  IP_INFO_PAGINATION,
  SET_USER,
  USER_PAGINATION
} from '../actions';

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: '',
  data: null,
  user: [],
  ipInfo: [],
  pagination: { current: 1, pageSize: 10 },
  userPagination: { current: 1, pageSize: 10 },
  totalCount: null,
  userTotalCount: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ASSET_AND_COLLECTION_COUNT:
      return { ...state, isLoading: false, data: payload?.res };
    case SET_USER:
      return { ...state, isLoading: false, user: payload?.res?.data, userPagination: { ...state.userPagination, total: payload?.res?.totalCount } };
    case USER_PAGINATION:
      return {
        ...state,
        userPagination: action.payload,
        stateChange: true,
      };
    case SET_ALL_IP_INFO:
      return { ...state, isLoading: false, ipInfo: payload?.res?.data, pagination: { ...state.pagination, total: payload?.res?.totalCount } };
    case IP_INFO_PAGINATION:
      return {
        ...state, pagination: action.payload, stateChange: true,
      };
    case SET_PENDING_DASHBOARD:
      return { ...state, isLoading: true };
    case SET_REJECTED_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isMessage: payload.message,
      };
    case RESET_STATE_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: false,
        isMessage: null,
      };
    default:
      return { ...state };
  }
}
