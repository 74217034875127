import {
  GET_ASSET_AND_COLLECTION_COUNT,
  SET_ASSET_AND_COLLECTION_COUNT,
  GET_ALL_IP_INFO, SET_ALL_IP_INFO,
  IP_INFO_PAGINATION,
  // <=========================COMMON==========================>
  SET_PENDING_DASHBOARD,
  SET_REJECTED_DASHBOARD,
  RESET_STATE_DASHBOARD,
  //==============================USER==========================>
  GET_USER,
  SET_USER,
  USER_PAGINATION
} from '../actions';

export const getAssestAndCollectionCount = (data) => ({
  type: GET_ASSET_AND_COLLECTION_COUNT,
  payload: data,
});

export const setAssestAndCollectionCount = (data) => ({
  type: SET_ASSET_AND_COLLECTION_COUNT,
  payload: data,
});
export const getUser = (data) => ({
  type: GET_USER,
  payload: data,
});
export const setUser = (data) => ({
  type: SET_USER,
  payload: data,
});
export const setUserPagination = data => ({
  type: USER_PAGINATION,
  payload: data,
});
export const getAllIpInfos = (data) => ({
  type: GET_ALL_IP_INFO,
  payload: data,
});

export const setAllIpInfo = (data) => ({
  type: SET_ALL_IP_INFO,
  payload: data,
});

export const ipInfoPagination = data => ({
  type: IP_INFO_PAGINATION,
  payload: data,
});

//<======================== common ==============================>
export const setPending = () => ({
  type: SET_PENDING_DASHBOARD,
});
export const setRejected = (data) => ({
  type: SET_REJECTED_DASHBOARD,
  payload: data,
});
export const reset = () => ({
  type: RESET_STATE_DASHBOARD,
});

