import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  // profile------------------------>
  getProfileAPI,
} from './API';

import { GET_PROFILE } from '../actions'
import { setProfileSuccess, setRejected } from './actions';
// import { openNotificationWithIcon } from '../../utils/notification';
// import JwtExpiredNotification from '../../utils/JwtExpiredNotification';
// import { openNotificationWithIcon } from '../../utils/notification';

// get admin profile --------------------------------------------------->
export function* getProfileSaga(action) {
  try {
    // yield put(setPending());
    const res = yield call(getProfileAPI);
    // console.log(res)
    yield put(setProfileSuccess({ data: res.data }));
  } catch (error) {
    const message =
      error.response.data.error || error.message || error.message.toString();
      // openNotificationWithIcon('error', 'Failed', message)
      // JwtExpiredNotification(message, action.payload)
      yield put(setRejected({ message }));
  }
}

export function* watchgetProfileSaga() {
  yield takeEvery(GET_PROFILE, getProfileSaga);
}

export default function* rootSaga() {
  yield all([fork(watchgetProfileSaga)]);
}
