import { openNotificationWithIcon } from './notification';

const JwtExpired = (message, history) => {
  if (
    message === 'jwt expired' ||
    message === 'Your account access has been denied' ||
    message === 'jwt malformed'
  ) {
    localStorage.removeItem('NFTPlatformAdmin');
    history && history.push('/');
  } else {
    openNotificationWithIcon('error', 'Failed', message);
  }
};

export default JwtExpired;
