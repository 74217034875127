import {
  GET_PROFILE,
  SET_PROFILE_SUCCESSFULLY,
  RESET_STATE,
  // <=========================COMMON==========================>
  SET_PENDING,
  SET_REJECTED
} from '../actions';


// get profile details----------------------------------------->
export const getProfile = (data) => ({
  type: GET_PROFILE,
  payload: data,
});
export const setProfileSuccess = (data) => ({
  type: SET_PROFILE_SUCCESSFULLY,
  payload: data,
});

export const setPending = () => ({
  type: SET_PENDING,
});
export const setRejected = (data) => ({
  type: SET_REJECTED,
  payload: data,
});
export const reset = () => ({
  type: RESET_STATE,
});
