import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
// import authUser from './auth/reducer';
//  import todoApp from './todo/reducer';
// import chatApp from './chat/reducer';
// import surveyListApp from './surveyList/reducer';
// import surveyDetailApp from './surveyDetail/reducer';
import PreMintAssets from './PremintAssests/reducer';
import GeneralPreMintAssets from './GeneralPremintAssest/reducer';
import GetCollection from "./collection/reducer"
import payment from "./payment/reducer"
// import registerAdmin from "./admin/reducer"
import auth from "./adminAuth/reducer"
import AdminSetting from "./adminSetting/reducer"
import Profile from "./profile/reducer"
import Dashboard from "./dashboard/reducer"

const reducers = combineReducers({
  menu,
  settings,
  // authUser,
  //  todoApp,
  // chatApp,
  // surveyListApp,
  // surveyDetailApp,
  // registerAdmin,
  PreMintAssets,
  GeneralPreMintAssets,
  GetCollection,
  payment,
  auth,
  AdminSetting,
  Profile,
  Dashboard
});

export default reducers;
