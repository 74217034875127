import {
  LOGIN_PLAFTORM_ADMIN,
  SET_FULFILLED_LOGIN,
  FORGET_PASSWORD,
  SET_PASSWORD_STATE,
  VERIFY_OTP,
  SET_FORM_STATE,
  VERIFIED_OTP_AND_RESET_PASSWORD,
  SET_DRAWER_VISIBLE,
  // GET_PROFILE,
  // SET_PROFILE_SUCCESSFULLY,
  LOGIN_PENDING_STATE,
  SET_OTP_PENDING,
  SET_TIMER,
  // SET_TIMMING,
  // <=========================COMMON==========================>
  SET_PENDING,
  SET_REJECTED,
  SET_MODAL_VISIBLE,
  RESET_STATE,
  SET_DATA,
} from '../actions';

export const getLoginPlatformAdmin = (data) => ({
  type: LOGIN_PLAFTORM_ADMIN,
  payload: data,
});
export const setFulfilledLogin = (data) => ({
  type: SET_FULFILLED_LOGIN,
  payload: data,
});
export const setPasswordState = data => ({
  type: SET_PASSWORD_STATE,
  payload: data
})
export const forgetPassword = (data) => ({
  type: FORGET_PASSWORD,
  payload: data,
});

export const verifyOTP = (data) => ({
  type: VERIFY_OTP,
  payload: data,
});
export const ResetPassword = data => ({
  type: VERIFIED_OTP_AND_RESET_PASSWORD,
  payload: data,
})
export const setFormState = (data) => ({
  type: SET_FORM_STATE,
  payload: data,
});

export const loginPendingState = (data) => ({
  type: LOGIN_PENDING_STATE,
});

export const setOTPpending = () => ({
  type: SET_OTP_PENDING,
});
export const setTimer = (data) => ({
  type: SET_TIMER,
  payload: data
});
// export const setTiming = (data) => ({
//   type: SET_TIMMING,
//   payload: data
// });

//<======================== common ==============================>
export const setPending = () => ({
  type: SET_PENDING,
});
export const setRejected = (data) => ({
  type: SET_REJECTED,
  payload: data,
});
export const reset = () => ({
  type: RESET_STATE,
});
export const setData = (data) => ({
  type: SET_DATA,
  payload: data,
});
export const setModalVisible = (data) => ({
  type: SET_MODAL_VISIBLE,
  payload: data,
});
export const setDrawerVisible = (data) => ({
  type: SET_DRAWER_VISIBLE,
  payload: data,
});