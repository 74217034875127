import { postRequest, getRequest } from "../../utils/request";
const url = 'api'

export const addFrontedURLAPI = data => postRequest(`/${url}/admin/addFrontendDomainUrl`, data)

export const updateFrontedURLAPI = data => postRequest(`/${url}/admin/updateFrontendDomainUrl`, data)

export const updatePasswordAPI = data => postRequest(`/${url}/auth/updatePassword`, data)

export const getInfuraURLAPI = ({page, limit}) => getRequest(`/${url}/infuraUrl/getInfuraUrl/${page}/${limit}`)

export const addInfuraURLAPI = data => postRequest(`/${url}/infuraUrl/setInfuraUrl`, data)

export const updateInfuraURLAPI = data => postRequest(`/${url}/infuraUrl/updateInfuraUrl`, data)

export const getEmailAPI = () => getRequest(`/${url}/emailCredential/getEmailCred`)

export const addEmailAPI = data => postRequest(`/${url}/emailCredential/setEmailCredential`, data)

export const updateEmailAPI = data => postRequest(`/${url}/emailCredential/updateEmailCredential`, data)