import {
    // SEND_PAYMENT_DETAILS,
    SETISENABLE_PAYMENT,
    SET_FIAT_PAYMENT,
    SET_FIAT_PAYMENT_STATE_UPDATED,
    // <=============COMMMON=====================>
    SET_PENDING,
    SET_REJECTED,
    RESET_STATE,
    SET_MODAL_VISIBLE
  } from '../actions';
  
  const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    isMessage: null,
    details: null,
    isVisible: {},
    iSPlatformAdmindetails: null,
    isStateUpdated: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case SETISENABLE_PAYMENT:
        // console.log(payload)
        return { ...state, isLoading: false, iSPlatformAdmindetails: payload };
      case SET_FIAT_PAYMENT:
        // console.log(payload)
        return { ...state, isLoading: false, details: payload, };
        case SET_MODAL_VISIBLE:
            // console.log(payload)
            return { ...state, isVisible: payload };
      case SET_PENDING:
        return { ...state, isLoading: true };
      case SET_FIAT_PAYMENT_STATE_UPDATED:
        return { ...state, isLoading: false, isSuccess: true, isMessage: action.payload.message, isStateUpdated: true };
      // case SET_FULFILLED:
      //   return { ...state, isLoading: false, isSuccess: true };
      case SET_REJECTED:
        // console.log(payload)
        return {
          ...state,
          isLoading: false,
          isError: true,
          isMessage: payload.message,
        };
      case RESET_STATE:
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          isError: false,
          isMessage: null,
          isStateUpdated: false,
        };
      default:
        return { ...state };
    }
  }
  