import {
  SET_PREMINT_ASSEST_COUNT,
  SET_UPLOAD_PREMINT_IMAGE,
  SET_FULFILLED_GET_TABLE_DATA,
  SET_FULFILLED_SHOW_HIDE_PREMINT_ASSET,
  SET_FULFILLED_CREATE_PREMINT_ASSET,
  SET_FULFILLED_GET_COLLECTION_SMART_CONTRACT_BY_ID,
  SET_CHECK,
  SET_CONTRACT_SUCCESS_STATE,
  SMART_CONTRACT_PENDING_STATE,
  SET_CONTRACT_SUCCESS_STATE_FOR_AIRDROP_AND_MERKLE_ROOT,
  SET_REJECT_UPLOAD_IMAGE,
  SET_PREMINT_MODAL_VISIBLE,
  SET_PREMINT_DRAWER_VISIBLE,
  SET_PREMINT_PENDING,
  SET_CREATE_ASSEST_JSON_SUCCESS,
  PREMINT_PAGINATION,
  // <=============COMMMON=====================>
  SET_DRAWER_VISIBLE,
  SET_MODAL_VISIBLE,
  SET_NESTING_MODAL_VISIBLE,
  SET_PENDING,
  SET_FULFILLED,
  SET_FULFILLED_WITH_VALUE,
  SET_REJECTED,
  RESET_STATE,
  SET_DATA,
  // <============Manual==================>
  FULFILLED_PUBLISH_MANUAL_PREMINT_ASSETS,
  REJECTED_PUBLISH_MANUAL_PREMINT_ASSETS,
  SET_MANUAL_PREMINT_ASSEST_SUCCES,
  // <================solana================>
  SET_SOLANA_PREMINT_ASSET,
  SET_SOLANA_IMGAE_TO_AWS,
} from '../actions';

const initialState = {
  isLoading: true,
  data: [],
  assestCount: '',
  status: null,
  error: null,
  image: null,
  thumbnail: null,
  updateShowAndHideSuccess: false,
  imgLoading: false,
  isVisible: {},
  isSuccess: false,
  isError: false,
  message: '',
  nestingModalIsVisible: {},
  isDrawerVisible: {},
  smartContractDetails: [],
  check: {},
  Details: null,
  stateChange: false,
  apiData: '',
  nestedState: false,
  pagination: { current: 1, pageSize: 10, },
  totalCount: null,
  isSmartContractLoading: false,
  // assetCreateSuccesFromJson: true,
  // access failed===========================>
  accessError: false,
  //<======== SolanaPremintAssets======>
  solanaPremintAssets: [],

  //<======== ManualPremintAssets======>
  manualStateChange: false,
  isPublishSuccess: false,
  isPublishError: false,
  isPublishMessage: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_FULFILLED_GET_TABLE_DATA:
      // console.log(payload)
      return {
        ...state,
        isLoading: false,
        // isSuccess: true,
        data: payload,
        pagination: {...state.pagination, total: payload.totalCount}

      };
    case SET_FULFILLED_SHOW_HIDE_PREMINT_ASSET:
      return {
        ...state,
        // isLoading: false,
        isSuccess: true,
        updateShowAndHideSuccess: true,
        // stateChange: true,
        message: payload.message,
      };
    case SET_PREMINT_ASSEST_COUNT:
      return { ...state, assestCount: payload };
    case SET_UPLOAD_PREMINT_IMAGE:
      return {
        ...state,
        image: action.payload.image,
        thumbnail: action.payload.thumbnail,
        // image: action.payload.image,
        imgLoading: action.payload.imgLoading,
      };
    case SET_REJECT_UPLOAD_IMAGE:
      // console.log(payload)
      const imgMsg = payload.message
      if(imgMsg === 'jwt expired' || imgMsg === 'Your account access has been denied'){
        return {
          ...state,
          image: action.payload.image,
          imgLoading: action.payload.imgLoading,
          thumbnail: action.payload?.image?.thumbnail,
          isError: true,
          message: action.payload.message,
          accessError: true,
        }
      } else {
        return {
          ...state,
          image: action.payload.image,
          imgLoading: action.payload.imgLoading,
          thumbnail: action.payload?.image?.thumbnail,
          isError: true,
          message: action.payload.message,
        };
      }
    case SET_FULFILLED_CREATE_PREMINT_ASSET:
      return {
        ...state,
        // isLoading: false,
        isSuccess: true,
        message: payload.message,
        stateChange: true,
        image: null,
        thumbnail: null,
      };
    case SET_FULFILLED_GET_COLLECTION_SMART_CONTRACT_BY_ID:
      // console.log(payload)
      return {
        ...state,
        isLoading: false,
        // isSuccess: true,
        smartContractDetails: payload,
      };
    case SET_CHECK:
      return { ...state, check: payload };
    case SET_CONTRACT_SUCCESS_STATE:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        nestedState: true,
        message: payload.message,
      };
    case SET_CONTRACT_SUCCESS_STATE_FOR_AIRDROP_AND_MERKLE_ROOT:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        nestingModalIsVisible: false,
        message: payload.message,
      };
    case SET_CREATE_ASSEST_JSON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        stateChange: true,
        message: payload.message,
      };
      case PREMINT_PAGINATION:
        // console.log(action.payload)
        return {
          ...state,
          pagination: action.payload,
          stateChange: true
        }
    // <=====================Manual=================>
    case FULFILLED_PUBLISH_MANUAL_PREMINT_ASSETS:
      // console.log(payload)
      return { ...state, isLoading: false, isPublishSuccess: true };
    case SET_MANUAL_PREMINT_ASSEST_SUCCES:
      // console.log(payload)
      return {
        ...state,
        // isLoading: false,
        isSuccess: true,
        manualStateChange: true,
        message: payload.message,
      };
    case REJECTED_PUBLISH_MANUAL_PREMINT_ASSETS:
      return {
        ...state,
        isLoading: false,
        isPublishError: true,
        isPublishMessage: payload.message,
      };
    // <===========solana==============>
    case SET_SOLANA_PREMINT_ASSET:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        solanaPremintAssets: payload,
      };
    case SET_SOLANA_IMGAE_TO_AWS:
      return {
        ...state,
        image: action.payload.image,
        imgLoading: action.payload.imgLoading,
      };
    // <=======================common==========================>
    case SET_DATA:
      // console.log(payload)
      return { ...state, Details: payload };
    case SET_NESTING_MODAL_VISIBLE:
      return { ...state, nestingModalIsVisible: payload };
    case SET_DRAWER_VISIBLE:
      return {
        ...state,
        isDrawerVisible: payload,
        image: null,
        thumbnail: null,
      };
    case SET_PREMINT_DRAWER_VISIBLE:
        return {
          ...state,
          isDrawerVisible: payload,
          image: null,
          thumbnail: null,
        };
    case SET_PREMINT_MODAL_VISIBLE:
      return { ...state, isVisible: payload };
    case SET_MODAL_VISIBLE:
      return { ...state, isVisible: payload };
    case SET_PENDING:
      return { ...state, isLoading: true };
    case SMART_CONTRACT_PENDING_STATE:
      return { ...state, isSmartContractLoading: true };
    case SET_PREMINT_PENDING:
      return { ...state, isLoading: true, isVisible: false, isDrawerVisible: false, nestingModalIsVisible: false  };
    case SET_FULFILLED:
      return { ...state, isLoading: false, isSuccess: true };
    case SET_FULFILLED_WITH_VALUE:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: payload.message,
        apiData: payload.data,
      };
    case SET_REJECTED:
      // console.log(payload)
      const msg = payload.message
      if(msg === 'jwt expired' || msg === 'Your account access has been denied'){
        return {
          ...state,
          isLoading: false,
          isError: true,
          message: payload.message,
          // isDrawerVisible: false,
          // isVisible: false,
          accessError: true,
        }
      } else {
        return {
          ...state,
          isLoading: false,
          isError: true,
          message: payload.message,
        };
      }
        case RESET_STATE:
      return {
        ...state,
        // isLoading: false,
        isSuccess: false,
        isError: false,
        message: '',
        nestedState: false,
        updateShowAndHideSuccess: false,
        manualStateChange: false,
        isPublishSuccess: false,
        isPublishError: false,
        stateChange: false,
        accessError: false,
        isSmartContractLoading: false
      };
    default:
      return { ...state };
  }
}
