import {
  SET_FULFILLED_LOGIN,
  SET_PASSWORD_STATE,
  SET_FORM_STATE,
  // SET_PROFILE_SUCCESSFULLY,
  LOGIN_PENDING_STATE,
  SET_OTP_PENDING,
  SET_TIMER,
  // SET_TIMMING,
  // <=============COMMMON=====================>
  SET_MODAL_VISIBLE,
  SET_PENDING,
  SET_REJECTED,
  RESET_STATE,
  SET_DATA,
} from '../actions';

const initialState = {
  isLoading: false,
  loginLoading: false,
  isSuccess: false,
  isError: false,
  isVisible: false,
  isMessage: '',
  isAuthenticated: false,
  formState: 0,
  details: '',
  data: null,
  isotpSendLoading: false,
  timer: false,
  timming: 5,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_FULFILLED_LOGIN:
      localStorage.setItem('NFT-Lux', payload.data);
      return { ...state, loginLoading: false, isAuthenticated: true };
    case SET_PASSWORD_STATE:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isMessage: payload.isMessage,
        formState: payload.formState,
        isVisible: payload.isVisible,
        isotpSendLoading: false
      };
    case SET_FORM_STATE:
      return { ...state, formState: payload };
    // case SET_PROFILE_SUCCESSFULLY:
    //   return { ...state, isLoading: false, data: payload.data };
    case LOGIN_PENDING_STATE:
      return { ...state, loginLoading: true };
      case SET_OTP_PENDING:
        return { ...state, isotpSendLoading: true, timer: true };
      case SET_TIMER:
        return { ...state, timer: payload };
      // case SET_TIMMING:
      //   console.log(payload)
      //   return { ...state, timming: payload-1 };
    // <=======================common==========================>
    case SET_DATA:
      return { ...state, details: payload };
    case SET_MODAL_VISIBLE:
      return { ...state, isVisible: payload, formState: 0 };
    case SET_PENDING:
      return { ...state, isLoading: true };

    case SET_REJECTED:
      return {
        ...state,
        isLoading: false,
        loginLoading: false,
        isError: true,
        isMessage: payload.message,
        isotpSendLoading: false 
      };
    case RESET_STATE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: false,
        isMessage: null,
        isAuthenticated: false,
      };
    default:
      return { ...state };
  }
}
