
// backend developement url  ---->>>      
// var apiBaseUrl = "https://dev.backend.drops.thenftbrewery.com"

// production
// export const apiBaseUrl = "https://backend.drops.thenftbrewery.com"
export const apiBaseUrl = "https://backend.drops.datacurve.io"


export function getAPiUrl() {
  return apiBaseUrl;
}


