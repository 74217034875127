import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  SEND_PAYMENT_DETAILS,
  GETISENABLE_PAYMENT,
  ENABLE_PAYMENT,
  GET_FIAT_PAYMENT,
} from '../actions';
import {
  // sendPaymentDetails,
  setIsEnablePaymentDetails,
  setFiatPaymentDetails,
  setPaymentStateUpdated,
  //   <============common=============>
  setPending,
  setRejected,
  setModalVisible,
  // reset,
} from './actions';

import {
  sendPaymentDetailsAPI,
  getIsEnablePaymentAPI,
  enablePaymentAPI,
  getFaitPaymentAPI,
} from './paymantAPI';
import JwtExpired from '../../utils/JwtExpired';
import JwtExpiredNotification from '../../utils/JwtExpiredNotification';

export function* sendPaymentDetailsSaga(action) {
  // console.log(action)
  try {
    yield put(setPending());
    const res = yield call(sendPaymentDetailsAPI, action.payload.data);
    yield put(setPaymentStateUpdated({ message: res.message }));
    // yield put(clearField());
  } catch (error) {
    const message =
      error.response.data.error || error.message || error.toString();
    yield put(setModalVisible(false));
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.props.history);
  }
}

export function* getIsEnablePaymentSaga(action) {
  // console.log(action)
  try {
    yield put(setPending());
    const res = yield call(getIsEnablePaymentAPI, action.payload);
    // console.log(res.data)
    yield put(setIsEnablePaymentDetails(...res?.data));
    // yield put(clearField());
  } catch (error) {
    const message =
      error.response.data.error || error.message || error.toString();
    yield put(setRejected({ message }));
    JwtExpiredNotification(message, action.payload.history);
  }
}

export function* getFaitPaymentSaga(action) {
  // console.log(action)
  try {
    yield put(setPending());
    const res = yield call(getFaitPaymentAPI);
    // console.log(res.data)
    yield put(setFiatPaymentDetails(res.data));
    // yield put(clearField());
  } catch (error) {
    console.log(error);
    const message =
      error.response.data.error || error.message || error.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.history);
  }
}

export function* enablePaymentSaga(action) {
  console.log(action);
  try {
    yield put(setPending());
    const res = yield call(enablePaymentAPI, action.payload.data);
    yield put(setPaymentStateUpdated({ message: res.message }));
    // yield put(clearField());
  } catch (error) {
    // console.log(error);
    const message =
      error.response.data.error || error.message || error.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.props.history);
  }
}

export function* watchSendPaymentDetails() {
  yield takeEvery(SEND_PAYMENT_DETAILS, sendPaymentDetailsSaga);
}
export function* watchGetIsEnablePaymentSaga() {
  yield takeEvery(GETISENABLE_PAYMENT, getIsEnablePaymentSaga);
}
export function* watchgetFaitPaymentSaga() {
  yield takeEvery(GET_FIAT_PAYMENT, getFaitPaymentSaga);
}
export function* watchIsEnablePaymentSaga() {
  yield takeEvery(ENABLE_PAYMENT, enablePaymentSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchSendPaymentDetails),
    fork(watchGetIsEnablePaymentSaga),
    fork(watchgetFaitPaymentSaga),
    fork(watchIsEnablePaymentSaga),
  ]);
}
