import { postRequest, getRequest } from '../../utils/request';
const url = 'api'

// <------------------------------------General------------------------------->
export const uploadPremintAssestImageAPI = data => postRequest(`/${url}/asset/assetFileUpload`, data);
export const createGeneralPremintAssestAPI = (newjsondata) => postRequest(`/${url}/generalPremint/createGeneralAsset`, newjsondata);
export const updateGeneralPremintAssestAPI = (newjsondata) => postRequest(`/${url}/generalPremint/updateGeneralAsset`, newjsondata);
export const updateShowHidePremintAssetAPI = data => postRequest(`/${url}/asset/updateShowItem`, data);
// <======================================== redeemed ==================================================>
export const getRedeemePremintAssetAPI = ({id, pagination: { current, pageSize}}) => 
getRequest(`/${url}/generalPremint/getRedeemeAsset/${id}/${current}/${pageSize}
`);
export const getAllRedeemedPremintAssetAPI = (id) => 
getRequest(`/${url}/generalPremint/getRedeemeAssetCSVFile/${id}
`);

// https://backend.drops.thenftbrewery.com/api/generalPremint/getRedeemeAssetCSVFile/:collection_id

