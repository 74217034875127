import { getRequest, postRequest } from '../../utils/request';

export const getCollectionData = ({ current, pageSize }) => getRequest(`/api/collection/allCollection/${current}/${pageSize}`)

export const CollectionApi = (data) => postRequest(`/api/collection/createCollection`, data);

export const updateVisibleCollectionApi = (data) => postRequest(`/api/collection/updateCollectionPublishItem`, data);

export const updateCollectionDetails = (data) => postRequest(`/api/collection/updateCollection`, data);

export const uploadCollectionImageAPI = data => postRequest(`/api/collection/uploadCollectionImage`, data);

export const getEnablePaymentAPI = () => getRequest(`/api/fiatPaymentSetting/clientGetOwnFiatPaymentSetting`)

export const updateFaitPaymentInCollectionAPI = data => postRequest(`/api/collection/updateCollectionFiatPaymentSupport`, data);
