import {
  SET_PREMINT_ASSEST_COUNT,
  SET_UPLOAD_PREMINT_IMAGE,
  SET_FULFILLED_GET_TABLE_DATA,
  SET_FULFILLED_CREATE_PREMINT_ASSET,
  SET_CHECK,
  SET_REJECT_UPLOAD_IMAGE,
  SET_PREMINT_MODAL_VISIBLE,
  SET_PREMINT_DRAWER_VISIBLE,
  SET_PREMINT_PENDING,
  PAGINAION_GENERAL_ASSET,
  SET_REDEEMED_GENERAL_ASSET,
  SET_PENDING_CSV_DOWNLOAD,
  // <=============COMMMON=====================>
  SET_DRAWER_VISIBLE,
  SET_MODAL_VISIBLE,
  SET_NESTING_MODAL_VISIBLE,
  SET_PENDING,
  SET_FULFILLED,
  SET_FULFILLED_WITH_VALUE,
  SET_REJECTED,
  RESET_STATE,
  SET_DATA,
  // <===============general=========================>
  SET_FULFILLED_SHOW_HIDE_GENERAL_PREMINT_ASSET,
  SET_ALL_REDEEMED_GENERAL_ASSET
} from '../actions';

const initialState = {
  isLoading: true,
  data: [],
  assestCount: '',
  status: null,
  error: null,
  image: null,
  thumbnail: null,
  updateShowAndHideSuccess: false,
  imgLoading: false,
  isVisible: {},
  isSuccess: false,
  isError: false,
  message: '',
  nestingModalIsVisible: {},
  isDrawerVisible: {},
  check: {},
  Details: null,
  stateChange: false,
  apiData: [],
  nestedState: false,
  pagination: { current: 1, pageSize: 10 },
  totalCount: null,
  redeemedData: [],
  allRedeemedData: [],
  isLoadingDownloadCSV: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_FULFILLED_GET_TABLE_DATA:
      return {
        ...state,
        isLoading: false,
        // isSuccess: true,
        data: payload,
        pagination: { ...state.pagination, total: payload.totalCount },
      };
    case SET_FULFILLED_SHOW_HIDE_GENERAL_PREMINT_ASSET:
      return {
        ...state,
        // isLoading: false,
        isSuccess: true,
        updateShowAndHideSuccess: true,
        // stateChange: true,
        message: payload.message,
      };
    case SET_PREMINT_ASSEST_COUNT:
      return { ...state, assestCount: payload };
    case SET_UPLOAD_PREMINT_IMAGE:
      return {
        ...state,
        image: action.payload.image,
        thumbnail: action.payload.thumbnail,
        // image: action.payload.image,
        imgLoading: action.payload.imgLoading,
      };
    case SET_REJECT_UPLOAD_IMAGE:
      // console.log(payload)
      const imgMsg = payload.message;
      if (
        imgMsg === 'jwt expired' ||
        imgMsg === 'Your account access has been denied'
      ) {
        return {
          ...state,
          image: action.payload.image,
          imgLoading: action.payload.imgLoading,
          thumbnail: action.payload?.image?.thumbnail,
          isError: true,
          message: action.payload.message,
          accessError: true,
        };
      } else {
        return {
          ...state,
          image: action.payload.image,
          imgLoading: action.payload.imgLoading,
          thumbnail: action.payload?.image?.thumbnail,
          isError: true,
          message: action.payload.message,
        };
      }
    case SET_FULFILLED_CREATE_PREMINT_ASSET:
      return {
        ...state,
        // isLoading: false,
        isSuccess: true,
        message: payload.message,
        stateChange: true,
        image: null,
        thumbnail: null,
      };

    case SET_CHECK:
      return { ...state, check: payload };
    case PAGINAION_GENERAL_ASSET:
      return {
        ...state,
        pagination: action.payload,
      };
      case SET_REDEEMED_GENERAL_ASSET:
        // console.log(payload)
        return {
          ...state,
          isLoading: false,
          message: payload.message,
          redeemedData: payload.data.data,
          totalCount: payload.data.totalCount,
          pagination: { ...state.pagination, total: payload?.data?.totalCount },
        };
      case SET_ALL_REDEEMED_GENERAL_ASSET:
        // console.log(payload)
        return {
          ...state,
          isLoadingDownloadCSV: false,
          message: payload.message,
          allRedeemedData: payload.data.data,
        };
    // <=======================common==========================>
    case SET_DATA:
      // console.log(payload)
      return { ...state, Details: payload };
    case SET_NESTING_MODAL_VISIBLE:
      return { ...state, nestingModalIsVisible: payload };
    case SET_DRAWER_VISIBLE:
      return {
        ...state,
        isDrawerVisible: payload,
        image: null,
        thumbnail: null,
      };
    case SET_PREMINT_DRAWER_VISIBLE:
      return {
        ...state,
        isDrawerVisible: payload,
        image: null,
        thumbnail: null,
      };
    case SET_PREMINT_MODAL_VISIBLE:
      return { ...state, isVisible: payload };
    case SET_MODAL_VISIBLE:
      return { ...state, isVisible: payload };
    case SET_PENDING:
      return { ...state, isLoading: true };
    case SET_PENDING_CSV_DOWNLOAD:
      return { ...state, isLoadingDownloadCSV: true };
    case SET_PREMINT_PENDING:
      return {
        ...state,
        isLoading: true,
        isVisible: false,
        isDrawerVisible: false,
        nestingModalIsVisible: false,
      };
    case SET_FULFILLED:
      return { ...state, isLoading: false, isSuccess: true };
    case SET_FULFILLED_WITH_VALUE:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: payload.message,
        apiData: payload.data,
      };

    case SET_REJECTED:
      // console.log(payload)
      const msg = payload.message;
      if (
        msg === 'jwt expired' ||
        msg === 'Your account access has been denied'
      ) {
        return {
          ...state,
          isLoading: false,
          isError: true,
          message: payload.message,
          // isDrawerVisible: false,
          // isVisible: false,
          accessError: true,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          isError: true,
          message: payload.message,
        };
      }
    case RESET_STATE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: '',
        nestedState: false,
      };
    default:
      return { ...state };
  }
}
