import { postRequest, getRequest } from '../../utils/request';
// import axios from 'axios';
// const Apibaseurl = require('../../constants/apibaseurl');
// var apibaseurl = Apibaseurl.getAPiUrl();
const url = 'api'

// <======================================== premint ==================================================>

export const getPremintAssest = ({id, pagination: {current, pageSize}}) => getRequest(`/${url}/asset/allPremintAsset/${id}/${current}/${pageSize}`)

export const createPremintAssest = newjsondata => postRequest(`/${url}/asset/preMint/uri`, newjsondata);

export const getPremintAssestCount = () => getRequest(`/${url}/countAllAsset`);

export const updatePremintAssest = (newjsondata) => postRequest(
    `/${url}/asset/updatePremintAsset`, newjsondata );

export const updateShowHidePremintAssetAPI = data => postRequest(`/${url}/asset/updateShowItem`, data);

export const uploadPremintAssestImageAPI = data => postRequest(`/${url}/asset/assetFileUpload`, data);

export const uploadPremintAssetJsonAPI = data => postRequest(`/${url}/asset/premintAssetWithJsonFile`, data );


// <================================ smart Contract ========================================>

export const registerCollectionSmartContractAPI = data =>  postRequest(
    `/${url}/collectionSmartContract/registerCollectionSmartContract`, data);

export const getCollectionSmartContractByIdAPI = ({
  collection_id, blockchainType, networkType, smartContractType }) => getRequest(
    `/${url}/collectionSmartContract/getCollectionSmartContract/${collection_id}/${blockchainType}/${networkType}/${smartContractType}`);

export const getUpdateCollectionSmartContractAPI = data => postRequest(
    `/${url}/collectionSmartContract/updateCollectionSmartContract`, data);

export const getAirdropCollectionSmartContractAPI = data => postRequest(
    `/${url}/airdrop/registerAirDropSmartContract`, data)

export const submitAirdropCollectionSmartContractAPI = data => postRequest(
    `/${url}/airdrop/submitAirDropDetails`, data);


// <===============================Manual=================================>

export const createManualPremintAssestAPI = (newjsondata) => postRequest(`/${url}/manualPremint/createManualPremintAsset`, newjsondata);

export const udateManualPremintAssestAPI = (newjsondata) => postRequest(`/${url}/manualPremint/updateManualPremintAsset`, newjsondata);

export const publishManualPremintAssestAPI = (data) => postRequest(`/${url}/manualPremint/publishManualPremintAsset`, data);

// <------------------------------------solana------------------------------->

export const createSolanaPremintAssestAPI = (newjsondata) => postRequest(`/${url}/solana/createPremintAsset`, newjsondata);

export const getSolanaPremintAssestAPI = (id) => getRequest(`$/{url}/solana/getSolanaPreMintAssets/${id}`);

export const uploadSolanaPremintAssestImageAPI = (data) => postRequest(`${url}/solana/uploadFile`, data);

// <------------------------------------General------------------------------->
export const createGeneralPremintAssestAPI = (newjsondata) => postRequest(`/${url}/generalPremint/createGeneralAsset`, newjsondata);
