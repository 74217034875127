import {
  SET_PROFILE_SUCCESSFULLY,
  SET_PENDING,
  SET_REJECTED
} from '../actions';

const initialState = {
  isLoading: false,
  loginLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  data: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PROFILE_SUCCESSFULLY:
      return { ...state, isLoading: false, data: payload.data, };

    // <=======================common==========================>
    case SET_PENDING:
      return { ...state, isLoading: true };
    case SET_REJECTED:
      return {
        ...state,
        isLoading: false,
        loginLoading: false,
        isError: true,
        isMessage: payload.message,
      };
    default:
      return { ...state };
  }
}
