import { call, takeEvery, all, fork, put, takeLatest } from 'redux-saga/effects';

import {
  GET_COLLECTIONS,
  CREATE_COLLECTION,
  UPDATE_COLLECTION,
  UPLOAD_IMAGE,
  UPDATE_COLLECTIONS_VISIBLE,
  GET_FAIT_PAYMENT_IN_COLLECTION,
  UPDATE_FAIT_PAYMENT_IN_COLLECTION,
} from '../actions';

import {
  // CreateCollectionSuccess,
  udpdateCollectionSuccess,
  setFulfilledCollections,
  setUploadImage,
  setRejectUploadImage,
  collectionPending,
  // udpdateFiatPaymentSuccess,
  setFiatPaymentData,
  // <============common============>
  setPending,
  // setFulfilledWithValue,
  setRejected,
  setDrawerVisible
} from './action';

import {
  getCollectionData,
  CollectionApi,
  updateCollectionDetails,
  uploadCollectionImageAPI,
  updateVisibleCollectionApi,
  getEnablePaymentAPI,
  updateFaitPaymentInCollectionAPI
} from './getCollection';

import JwtExpired from '../../utils/JwtExpired';
import JwtExpiredNotification from '../../utils/JwtExpiredNotification';

// get all collections-------------------------------------------------->
export function* getCollectionTableData(action) {
  // console.log(action)
  try {
    // console.log('run')
    yield put(setDrawerVisible(false));
    yield put(collectionPending());
    // yield put(setPending());
    // const res = yield call(getCollectionData, action.payload);
    const res = yield call(getCollectionData, action.payload.pagination);
    // console.log(res)
    yield put(setFulfilledCollections({ data: res }));
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({ message }));
    // JwtExpired(message, action.payload.history)
    JwtExpired(message, action.payload.history);
  }
}

// upload image get image url and thumbnails-------------------------------->
export function* uploadImageData(action) {
  try {
    yield put(setUploadImage({image:null, imgLoading: true}))
    const res = yield call(uploadCollectionImageAPI, action.payload.data);
    yield put(setUploadImage({image:res?.message, imgLoading: false,}))
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejectUploadImage({image:null, imgLoading: false, message }))
    JwtExpired(message, action.payload.history)
  }
}

// create Collection-------------------------------------------------------->
export function* createCollectionSaga(action) {
  try {
    yield put(setPending());
    const res = yield call(CollectionApi, action.payload.data);
    yield put(udpdateCollectionSuccess({ message: res.message, data: null }));
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

// update Collection------------------------------------------------------>
export function* UpdateCollectionSaga(action) {
  try {
    yield put(setPending());
    const res = yield call(updateCollectionDetails, action.payload.data);
    yield put(udpdateCollectionSuccess({ message: res.message, data: null }))
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

// update collection show or not on fronted -------------------------------
export function*updateCollecitonVisibleDetails(action){
  try {
    yield put(setPending());
    const res = yield call(updateVisibleCollectionApi, action.payload.data)
    yield put(udpdateCollectionSuccess({ message: res.message, data: null}))
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

// get fiat payment data ------------------------------------>
export function* getFaitPaymentInCollectionSaga(action){
  try {
    // console.log(action)
    // yield put(setPending());
    const res = yield call(getEnablePaymentAPI)
    // console.log(res)
    yield put(setFiatPaymentData({ data: res.data }))
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    // JwtExpired( message, action.payload.history )
    JwtExpiredNotification(message, action.payload.history);

  }
}

// enable or disable fiat payment ------------------------------------>
export function*updateFaitPaymentInCollectionSaga(action){
  try {
    yield put(setPending());
    const res = yield call(updateFaitPaymentInCollectionAPI, action.payload.data)
    yield put(udpdateCollectionSuccess({ message: res.message, data: null}))
  } catch (error) {
    const message = error.response.data.error || error.message || error.message.toString()
    yield put(setRejected({message}));
    JwtExpired( message, action.payload.history )
  }
}

export function* watchGetCollections() {
  yield takeEvery(GET_COLLECTIONS, getCollectionTableData);
}
export function* watchuploadImageData() {
  yield takeLatest(UPLOAD_IMAGE, uploadImageData);
}
export function* watchcreateCollection() {
  yield takeEvery(CREATE_COLLECTION, createCollectionSaga);
}
export function* watchsendUpdateCollectionDara() {
  yield takeEvery(UPDATE_COLLECTION, UpdateCollectionSaga);
}
export function* watchupdateCollecitonVisibleDetails() {
  yield takeEvery(UPDATE_COLLECTIONS_VISIBLE, updateCollecitonVisibleDetails);
}
export function* watchupdateFaitPaymentInCollectionSaga() {
  yield takeEvery(UPDATE_FAIT_PAYMENT_IN_COLLECTION, updateFaitPaymentInCollectionSaga);
}
export function* watchGetFaitPaymentInCollectionSaga() {
  yield takeEvery(GET_FAIT_PAYMENT_IN_COLLECTION, getFaitPaymentInCollectionSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCollections),
    fork(watchuploadImageData),
    fork(watchcreateCollection),
    fork(watchsendUpdateCollectionDara),
    fork(watchupdateCollecitonVisibleDetails),
    fork(watchGetFaitPaymentInCollectionSaga),
    fork(watchupdateFaitPaymentInCollectionSaga),
  ]);
}
