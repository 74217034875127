import {
  SEND_PAYMENT_DETAILS,
  GETISENABLE_PAYMENT,
  SETISENABLE_PAYMENT,
  ENABLE_PAYMENT,
  GET_FIAT_PAYMENT,
  SET_FIAT_PAYMENT,
  SET_FIAT_PAYMENT_STATE_UPDATED,
//   <==================common===================>
  SET_PENDING,
  SET_FULFILLED,
  SET_REJECTED,
  RESET_STATE,
  SET_MODAL_VISIBLE
} from '../actions';

export const sendPaymentDetails = (data) => ({
  type: SEND_PAYMENT_DETAILS,
  payload: data,
});

export const getIsEnablePaymentDetails = (data) => ({
  type: GETISENABLE_PAYMENT,
  payload: data,
});
export const getFiatPaymentDetails = (data) => ({
  type: GET_FIAT_PAYMENT,
  payload: data,
});
export const setFiatPaymentDetails = (data) => ({
  type: SET_FIAT_PAYMENT,
  payload: data,
});

export const setIsEnablePaymentDetails = (data) => ({
  type: SETISENABLE_PAYMENT,
  payload: data,
});

export const enablePaymentHandler = (data) => ({
  type: ENABLE_PAYMENT,
  payload: data,
});
export const setPaymentStateUpdated = (data) => ({
  type: SET_FIAT_PAYMENT_STATE_UPDATED,
  payload: data,
});

//<======================== common ==============================>

export const setPending = () => ({
  type: SET_PENDING,
});
export const setFulfilled = () => ({
  type: SET_FULFILLED,
});
export const setRejected = (data) => ({
  type: SET_REJECTED,
  payload: data,
});
export const reset = () => ({
  type: RESET_STATE,
});
export const setModalVisible = (data) => ({
    type: SET_MODAL_VISIBLE,
    payload:data
  });