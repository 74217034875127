import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  LOGIN_PLAFTORM_ADMIN,
  FORGET_PASSWORD,
  VERIFY_OTP,
  VERIFIED_OTP_AND_RESET_PASSWORD,
  // GET_PROFILE,
} from '../actions';

import {
  loginAPI,
  sendOtpAndforgetPasswordAPI,
  verifyOTPAndForgetPasswordAPI,
  resetPasswordAPI,
  // getProfileAPI,
} from './API';

import {
  setFulfilledLogin,
  setPasswordState,
  // setProfileSuccess,
  setPending,
  setRejected,
  loginPendingState,
  setOTPpending,
  reset
} from './actions';
// import { openNotificationWithIcon } from '../../utils/notification';
import JwtExpired from '../../utils/JwtExpired';

// login------------------------------------------------------->
export function* loginPlatformAdminSaga(action) {
  try {
    yield put(reset())
    yield put(loginPendingState());
    const res = yield call(loginAPI, action.payload);
    yield put(setFulfilledLogin({ data: res?.token }));
  } catch (error) {
    const message =
      error.response.data.error || error.message || error.message.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action?.payload?.props?.history)
  }
}

// set OTP to email address to forget password-------------------------->
export function* sendOtpForgetPasswordSaga(action) {
  try {
    yield put(setOTPpending());
    // yield put(setPending());
    const res = yield call(sendOtpAndforgetPasswordAPI, action.payload);
    console.log(res.token);
    yield put(
      setPasswordState({
        data: res?.token,
        isMessage: res.message,
        formState: 1,
        isVisible: true,
      })
    );
  } catch (error) {
    const message =
    error.response.data.error || error.message || error.message.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.props.history)
  }
}

// verify OTP and forget password----------------------------------------------->
export function* verifyOtpAndForgetPasswordSaga(action) {
  try {
    yield put(setPending());
    const res = yield call(verifyOTPAndForgetPasswordAPI, action.payload);
    console.log(res.token);
    yield put(
      setPasswordState({
        data: res?.token,
        isMessage: res.message,
        formState: 2,
        isVisible: true,
      })
    );
    // yield put(setFulfilleForgetPassword({ data: res?.token, isMessage: res.message }))
  } catch (error) {
    const message =
    error.response.data.error || error.message || error.message.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.props.history)
  }
}

// set new password -------------------------------------------------------->
export function* resetPasswordSaga(action, getState) {
  console.log(getState);
  try {
    yield put(setPending());
    const res = yield call(resetPasswordAPI, action.payload);
    // console.log(res.token)
    yield put(
      setPasswordState({
        data: res?.token,
        isMessage: res.message,
        formState: 0,
        isVisible: false,
      })
    );
  } catch (error) {
    const message =
    error.response.data.error || error.message || error.message.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.props.history)
  }
}

export function* watchloginPlatformAdminSaga() {
  yield takeEvery(LOGIN_PLAFTORM_ADMIN, loginPlatformAdminSaga);
}
export function* watchSendOtpForgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, sendOtpForgetPasswordSaga);
}
export function* watchVerifyOtpAndForgetPasswordSaga() {
  yield takeEvery(VERIFY_OTP, verifyOtpAndForgetPasswordSaga);
}
export function* watchResetPasswordSaga() {
  yield takeEvery(VERIFIED_OTP_AND_RESET_PASSWORD, resetPasswordSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchloginPlatformAdminSaga),
    fork(watchSendOtpForgetPasswordSaga),
    fork(watchVerifyOtpAndForgetPasswordSaga),
    fork(watchResetPasswordSaga),
  ]);
}
