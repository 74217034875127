import { all, call, put, takeEvery, fork } from "redux-saga/effects" 
import JwtExpired from "../../utils/JwtExpired";
import { getProfile } from "../profile/actions";
import { ADD_FRONTED_URL, UPDATE_FRONTED_URL, UPDATE_PASSWORD, GET_INFURA_URL, ADD_INFURA_URL, UPDATE_INFURA_URL, GET_EMAIL, ADD_EMAIL, UPDATE_EMAIL } from "../actions";
import { addFrontedURLAPI, updateFrontedURLAPI, getInfuraURLAPI, addInfuraURLAPI, updateInfuraURLAPI, updatePasswordAPI, getEmailAPI, addEmailAPI, updateEmailAPI } from "./API";
import { setPending, setFulfilledWithValue, setRejected, setFulfilledUpdatePassword, getInfuraURL, setModalVisible, setInfuraDetails, setEmailDetails, getEmail } from "../adminSetting/actions";

export function* addFrontendDomainUrlSaga (action) {
    try {
        yield put(setPending());
        const res = yield call(addFrontedURLAPI, action.payload.data)
        yield put(setFulfilledWithValue({ message: res.message, data: '' }))
        JwtExpired(res.message, action.payload.history, 'success')
        yield put(setModalVisible(false))
        yield put(getProfile())
    } catch (error) {
        const message = error.response.data.error || error.message || error.message.toString()
        yield put(setModalVisible(false))
        JwtExpired(message, action.payload.history)
    }
}

export function* updateFrontendDomainUrlSaga (action) {
    try {
        yield put(setPending());
        const res = yield call(updateFrontedURLAPI, action.payload.data)
        yield put(setFulfilledWithValue({ message: res.message, data: '' }))
        JwtExpired(res.message, action.payload.history, 'success')
        yield put(setModalVisible(false))
        yield put(getProfile())
    } catch (error) {
        const message = error.response.data.error || error.message || error.message.toString()
        yield put(setModalVisible(false))
        JwtExpired(message, action.payload.history)
    }
}

export function* getInfuraUrlSaga (action) {
    try {
        yield put(setPending());
        const res = yield call(getInfuraURLAPI, action.payload.data)
        yield put(setInfuraDetails({ message: res.message, data: res.data }))
    } catch (error) {
        const message = error.response.data.error || error.message || error.message.toString()
        yield put(setRejected({ message }));
        JwtExpired(message, action.payload.history)
    }
}

export function* addInfuraUrlSaga (action) {
    try {
        yield put(setPending());
        const res = yield call(addInfuraURLAPI, action.payload.data)
        yield put(setModalVisible(false))
        JwtExpired(res.message, action.payload.history, 'success')
        yield put(getInfuraURL({history: action.payload.history, data: { page: 1, limit: 10 } }))
    } catch (error) {
        const message = error.response.data.error || error.message || error.message.toString()
        yield put(setRejected({ message }));
        JwtExpired(message, action.payload.history)
    }
}

export function* updateInfuraUrlSaga (action) {
    try {
        yield put(setPending());
        const res = yield call(updateInfuraURLAPI, action.payload.data)
        yield put(setModalVisible(false))
        JwtExpired(res.message, action.payload.history, 'success')
        yield put(getInfuraURL({history: action.payload.history, data: { page: 1, limit: 10 } }))
    } catch (error) {
        const message = error.response.data.error || error.message || error.message.toString()
        yield put(setRejected({ message }));
        JwtExpired(message, action.payload.history)
    }
}

export function* getEmailSaga (action) {
    try {
        yield put(setPending());
        const res = yield call(getEmailAPI)
        yield put(setEmailDetails({ message: res.message, data: [res.data] }))
    } catch (error) {
        const message = error?.response?.data?.error || error?.message || error?.message.toString()
        yield put(setRejected({ message }));
        JwtExpired(message, action.payload.history)
    }
}

export function* addEmailSaga (action) {
    try {
        yield put(setPending());
        const res = yield call(addEmailAPI, action.payload.data)
        yield put(setModalVisible(false))
        JwtExpired(res.message, action.payload.history, 'success')
        yield put(getEmail({history: action.payload.history }))
    } catch (error) {
        const message = error.response.data.error || error.message || error.message.toString()
        yield put(setRejected({ message }));
        JwtExpired(message, action.payload.history)
    }
}

export function* updateEmailSaga (action) {
    try {
        yield put(setPending());
        const res = yield call(updateEmailAPI, action.payload.data)
        yield put(setModalVisible(false))
        JwtExpired(res.message, action.payload.history, 'success')
        yield put(getEmail({history: action.payload.history }))
    } catch (error) {
        const message = error.response.data.error || error.message || error.message.toString()
        yield put(setRejected({ message }));
        JwtExpired(message, action.payload.history)
    }
}

export function* updatePasswordAPISaga (action) {
    try {
        yield put(setPending());
        const res = yield call(updatePasswordAPI, action.payload.data)
        yield put(setFulfilledUpdatePassword({ message: res.message, data: '' }))
        yield put(setModalVisible(false))
        JwtExpired(res.message, action.payload.history, 'success')
    } catch (error) {
        const message = error.response.data.error || error.message || error.message.toString()
        yield put(setModalVisible(false))
        JwtExpired(message, action.payload.history)
    }
}

export function* watchGetInfuraUrlSaga() {
    yield takeEvery(GET_INFURA_URL, getInfuraUrlSaga)
}
export function* watchAddInfuraUrlSaga() {
    yield takeEvery(ADD_INFURA_URL, addInfuraUrlSaga)
}
export function* watchUpdateInfuraUrlSaga() {
    yield takeEvery(UPDATE_INFURA_URL, updateInfuraUrlSaga)
}
export function* watchGetEmailSaga() {
    yield takeEvery(GET_EMAIL, getEmailSaga)
}
export function* watchAddEmailSaga() {
    yield takeEvery(ADD_EMAIL, addEmailSaga)
}
export function* watchUpdateEmailSaga() {
    yield takeEvery(UPDATE_EMAIL, updateEmailSaga)
}
export function* watchAddFrontendDomainUrlSaga() {
    yield takeEvery(UPDATE_FRONTED_URL, updateFrontendDomainUrlSaga)
}
export function* watchUpdateFrontendDomainUrlSaga() {
    yield takeEvery(ADD_FRONTED_URL, addFrontendDomainUrlSaga)
}
export function* watchUpdatePasswordAPISaga() {
    yield takeEvery(UPDATE_PASSWORD, updatePasswordAPISaga)
}

export default function* rootSage(){
    yield(all([
        fork(watchAddFrontendDomainUrlSaga),
        fork(watchUpdateFrontendDomainUrlSaga),
        fork(watchUpdatePasswordAPISaga),
        fork(watchGetInfuraUrlSaga),
        fork(watchAddInfuraUrlSaga),
        fork(watchUpdateInfuraUrlSaga),
        fork(watchGetEmailSaga),
        fork(watchAddEmailSaga),
        fork(watchUpdateEmailSaga)
    ]))
}