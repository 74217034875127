import {
    // <=========================COMMON==========================>
    SET_PENDING,
    SET_FULFILLED,
    SET_FULFILLED_WITH_VALUE,
    SET_REJECTED,
    RESET_STATE,
    SET_MODAL_VISIBLE,
    SET_DRAWER_VISIBLE,
    // <----------------------General----------------------->
    CREATE_GENERAL_PREMINT_ASSET,
    UPLOAD_PREMINT_IMAGE,
    SET_UPLOAD_PREMINT_IMAGE,
    SET_REJECT_UPLOAD_IMAGE,
    UPDATE_GENERAL_PREMINT_ASSET,
    PAGINAION_GENERAL_ASSET,
    // Show hide general premint asset----------------->
    UPDATE_GENERAL_PREMINT_ASSEST_SHOW_AND_HIDE,
    SET_FULFILLED_SHOW_HIDE_GENERAL_PREMINT_ASSET,
    // --------------------------REDEEMED----------------------------->
    GET_REDEEMED_GENERAL_ASSET,
    SET_REDEEMED_GENERAL_ASSET,
    SET_ALL_REDEEMED_GENERAL_ASSET,
    GET_ALL_REDEEMED_GENERAL_ASSET,
    SET_PENDING_CSV_DOWNLOAD
  } from '../actions';

// <========================= create General Premint Asset
export const createGeneralPremintAssest = (data) => ({
    type: CREATE_GENERAL_PREMINT_ASSET,
    payload: data,
  });

  export const uploadPreimintImage = (data) => ({
    type: UPLOAD_PREMINT_IMAGE,
    payload: data,
  });
  export const setUploadPreiminImage = (data) => ({
    type: SET_UPLOAD_PREMINT_IMAGE,
    payload: data,
  });
  
  export const setRejectUploadImage = (data) => ({
    type: SET_REJECT_UPLOAD_IMAGE,
    payload: data,
  });

  export const updateGeneralPremintAssest = (data) => ({
    type: UPDATE_GENERAL_PREMINT_ASSET,
    payload: data
  })

  // show and hide general premint asset===============================================>
  export const updateGeneralPremitnAssetShowAndHide = (data) => ({
    type: UPDATE_GENERAL_PREMINT_ASSEST_SHOW_AND_HIDE,
    payload: data,
  });
  
  export const setFulfilledupdateGeneralPremitnAssetShowAndHide = (data) => ({
    type: SET_FULFILLED_SHOW_HIDE_GENERAL_PREMINT_ASSET,
    payload: data,
  });

  // get Redeeme Premint Asset =================================================================>
  export const getRedeemedGeneralAsset = (data) => ({
    type: GET_REDEEMED_GENERAL_ASSET,
    payload: data,
  });

  export const setRedeemedGeneralAsset = (data) => ({
    type: SET_REDEEMED_GENERAL_ASSET,
    payload: data,
  });
  export const setPendingDownloadCSV = () => ({
    type: SET_PENDING_CSV_DOWNLOAD,
  });
  export const getAllRedeemedGeneralAsset = (data) => ({
    type: GET_ALL_REDEEMED_GENERAL_ASSET,
    payload: data,
  });
  
  export const setAllRedeemedGeneralAsset = (data) => ({
    type: SET_ALL_REDEEMED_GENERAL_ASSET,
    payload: data,
  });

  export const generalTablePagination = (data) => ({
    type: PAGINAION_GENERAL_ASSET,
    payload: data,
  });
 
//<======================== common ==============================>

export const setPending = () => ({
    type: SET_PENDING,
  });

  export const setFulfilled = () => ({
    type: SET_FULFILLED,
  });
  export const setFulfilledWithValue = data => ({
    type: SET_FULFILLED_WITH_VALUE,
    payload: data
  })
  export const setRejected = (data) => ({
    type: SET_REJECTED,
    payload: data,
  });
//   export const setData = (data) => ({
//     type: SET_DATA,
//     payload: data,
//   });
  
  export const setModalVisible = (data) => ({
    type: SET_MODAL_VISIBLE,
    payload: data,
  });
  export const setDrawerVisible = (data) => ({
    type: SET_DRAWER_VISIBLE,
    payload: data,
  });
//   export const setNestingModalVisible = (data) => ({
//     type: SET_NESTING_MODAL_VISIBLE,
//     payload: data,
//   });
  export const reset = () => ({
    type: RESET_STATE,
  });