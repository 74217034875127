import {
  GET_COLLECTIONS,
  SET_COLLECTIONS,
  UPDATE_COLLECTIONS_VISIBLE,
  CREATE_COLLECTION,
  UPDATE_COLLECTION,
  UPLOAD_IMAGE,
  SET_UPLOAD_IMAGE,
  SET_REJECT_UPLOAD_IMAGE,
  UPDATE_FAIT_PAYMENT_IN_COLLECTION,
  UPDATE_IN_COLLECTION_SUCCESSFULLY,
  COLLECTION_PENDING,
  // UPDATE_FIAT_PAYMENT_SUCCESSFULLY,
  GET_FAIT_PAYMENT_IN_COLLECTION,
  SET_FIAT_PAYMENT_DATA,
  COLLECTION_PAGINATION,
  // <=========================COMMON==========================>
  SET_DRAWER_VISIBLE,
  SET_MODAL_VISIBLE,
  SET_NESTING_MODAL_VISIBLE,
  SET_PENDING,
  // SET_FULFILLED_WITH_VALUE,
  SET_REJECTED,
  RESET_STATE,
  SET_DATA,
} from '../actions';

export const getCollections = (data) => ({
  type: GET_COLLECTIONS,
  payload: data,
});

export const setFulfilledCollections = (data) => ({
  type: SET_COLLECTIONS,
  payload: data,
});

export const uploadImage = (data) => ({
  type: UPLOAD_IMAGE,
  payload: data,
});

export const setUploadImage = (data) => ({
  type: SET_UPLOAD_IMAGE,
  payload: data,
});
export const setRejectUploadImage = (data) => ({
  type: SET_REJECT_UPLOAD_IMAGE,
  payload: data,
});

export const createCollectionDetials = (data) => ({
  type: CREATE_COLLECTION,
  payload: data,
});

export const updateCollection = (data) => ({
  type: UPDATE_COLLECTION,
  payload: data,
});

export const updateCollectionVisible = (data) => ({
  type: UPDATE_COLLECTIONS_VISIBLE,
  payload: data,
});

export const updateFiatPayment = (data) => ({
  type: UPDATE_FAIT_PAYMENT_IN_COLLECTION,
  payload: data,
});

export const udpdateCollectionSuccess = data => ({
  type: UPDATE_IN_COLLECTION_SUCCESSFULLY,
  payload: data 
})

export const getFiatPayment = (data) => ({
  type: GET_FAIT_PAYMENT_IN_COLLECTION,
  payload: data,
});

export const setFiatPaymentData = (data) => ({
  type: SET_FIAT_PAYMENT_DATA,
  payload: data,
});

export const collectionPending = () => ({
  type: COLLECTION_PENDING,
  // payload: data,
});

export const collectionPagination = data => ({
  type: COLLECTION_PAGINATION,
  payload: data,
});

// const setUpdateFaitPay


//<======================== common ==============================>

export const setPending = () => ({
  type: SET_PENDING,
});
export const setRejected = (data) => ({
  type: SET_REJECTED,
  payload: data,
});
// export const setFulfilled = (data) => ({
//   type: SET_FULFILLED,
//   payload: data,
// });
// export const setFulfilledWithValue = (data) => ({
//   type: SET_FULFILLED_WITH_VALUE,
//   payload: data,
// });
export const setData = (data) => ({
  type: SET_DATA,
  payload: data,
});
export const setModalVisible = (data) => ({
  type: SET_MODAL_VISIBLE,
  payload: data,
});
export const setNestingModalVisible = (data) => ({
  type: SET_NESTING_MODAL_VISIBLE,
  payload: data,
});
export const setDrawerVisible = (data) => ({
  type: SET_DRAWER_VISIBLE,
  payload: data,
});
export const reset = () => ({
  type: RESET_STATE,
});
