// import { apiBaseUrl } from './enviroment';
import axios from 'axios';
const Apibaseurl = require('../constants/apibaseurl');
var apibaseurl = Apibaseurl.getAPiUrl();

export const postRequest = (url, data) => {
  //   const assestData = [];
  const config = {
    headers: {
      Authorization: localStorage.getItem("NFT-Lux")
        ? `Bearer ${localStorage.getItem("NFT-Lux")}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return axios
    .post(`${apibaseurl}${url}`, data, config)
    .then((res) => res.data);
};

export const getRequest = (url, data) => {
  //   const assestData = [];
  const config = {
    headers: {
      Authorization: localStorage.getItem("NFT-Lux")
        ? `Bearer ${localStorage.getItem("NFT-Lux")}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return axios.get(`${apibaseurl}${url}`, config).then((res) => res.data);
};
