import {
  ADD_FRONTED_URL,
  UPDATE_FRONTED_URL,
  ADD_INFURA_URL,
  GET_INFURA_URL,
  UPDATE_INFURA_URL,
  SET_INFURA_DETAILS,
  SET_FULFILLED_WITH_VALUE,
  UPDATE_PASSWORD,
  SET_FULFILLED_UPDATE_PASSWORD,
  GET_EMAIL,
  ADD_EMAIL,
  UPDATE_EMAIL,
  SET_EMAIL_DETAILS,
  // <=========================COMMON==========================>
  SET_DRAWER_VISIBLE,
  SET_MODAL_VISIBLE,
  SET_NESTING_MODAL_VISIBLE,
  SET_PENDING,
  SET_FULFILLED,
  SET_REJECTED,
  RESET_STATE,
  SET_DATA,
} from '../actions';

export const addFrontendURL = data => ({
  type: ADD_FRONTED_URL,
  payload: data
})

export const updateFrontendURL = (data) => ({
  type: UPDATE_FRONTED_URL,
  payload: data,
});
export const setInfuraDetails = data => ({
  type: SET_INFURA_DETAILS,
  payload: data
})
export const getInfuraURL = data => ({
  type: GET_INFURA_URL,
  payload: data
})
export const addInfuraURL = data => ({
  type: ADD_INFURA_URL,
  payload: data
})
export const updateInfuraURL = data => ({
  type: UPDATE_INFURA_URL,
  payload: data
})
export const updatePassword = (data) => ({
  type: UPDATE_PASSWORD,
  payload: data,
});
export const setFulfilledUpdatePassword = (data) => ({
  type: SET_FULFILLED_UPDATE_PASSWORD,
  payload: data,
});
export const getEmail = data => ({
  type: GET_EMAIL,
  payload: data
})
export const addEmail = data => ({
  type: ADD_EMAIL,
  payload: data
})
export const setEmailDetails = data => ({
  type: SET_EMAIL_DETAILS,
  payload: data
})
export const updateEmail = data => ({
  type: UPDATE_EMAIL,
  payload: data
})

//<======================== common ==============================>
export const setData = (data) => ({
  type: SET_DATA,
  payload: data,
});
export const setModalVisible = (data) => ({
  type: SET_MODAL_VISIBLE,
  payload: data,
});
export const setDrawerVisible = (data) => ({
  type: SET_DRAWER_VISIBLE,
  payload: data,
});
export const setNestingModalVisible = (data) => ({
  type: SET_NESTING_MODAL_VISIBLE,
  payload: data,
});
export const setPending = () => ({
  type: SET_PENDING,
});
export const setFulfilled = (data) => ({
  type: SET_FULFILLED,
  payload: data,
});
export const setFulfilledWithValue = (data) => ({
  type: SET_FULFILLED_WITH_VALUE,
  payload: data,
});
export const setRejected = (data) => ({
  type: SET_REJECTED,
  payload: data,
});
export const reset = () => ({
  type: RESET_STATE,
});
