import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  setUploadPreiminImage,
  setRejectUploadImage,
  setFulfilledupdateGeneralPremitnAssetShowAndHide,
  setRedeemedGeneralAsset,
  setAllRedeemedGeneralAsset,
  setPendingDownloadCSV,
  //   common
  setPending,
  setRejected,
  setFulfilledWithValue,
} from './actions';
import {
  uploadPremintAssestImageAPI,
  createGeneralPremintAssestAPI,
  updateGeneralPremintAssestAPI,
  updateShowHidePremintAssetAPI,
  getRedeemePremintAssetAPI,
  getAllRedeemedPremintAssetAPI
} from './API';
import {
  CREATE_GENERAL_PREMINT_ASSET,
  UPDATE_GENERAL_PREMINT_ASSET,
  UPDATE_GENERAL_PREMINT_ASSEST_SHOW_AND_HIDE,
  GET_REDEEMED_GENERAL_ASSET,
  GET_ALL_REDEEMED_GENERAL_ASSET
} from '../actions';
import JwtExpired from '../../utils/JwtExpired';
import { getPremintDetails } from '../PremintAssests/aciotns';

// uppload general premint  asset image and get ipfs and thumbnails----------------------------------->
export function* uploadPremintImageData(action) {
  try {
    yield put(setUploadPreiminImage({ image: null, imgLoading: true }));
    const response = yield call(
      uploadPremintAssestImageAPI,
      action.payload.data
    );
    yield put(
      setUploadPreiminImage({
        image: response?.message?.hash,
        thumbnail: response?.message?.thumbnail,
        imgLoading: false,
      })
    );
  } catch (error) {
    const message =
      error.response.data.error || error.message || error.message.toString();
    yield put(
      setRejectUploadImage({ image: null, imgLoading: false, message })
    );
    JwtExpired(message, action.payload.history);
  }
}

// create general premint asset---------------------------------------------->
export function* createGeneralAssetSaga(action) {
  try {
    yield put(setPending());
    const data = yield call(createGeneralPremintAssestAPI, action.payload.data);
    yield put(setFulfilledWithValue({ data: null, message: data.message }));
    yield put(getPremintDetails({data:action?.payload?.getPremintData }));
  } catch (error) {
    const message =
      error.response.data.error || error.message || error.message.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.history);
  }
}

// // update general premint asset--------------------------------------------->
export function* updateGeneralPremintAssestSaga(action) {
  try {
    yield put(setPending());
    const data = yield call(updateGeneralPremintAssestAPI, action.payload.data);
    yield put(setFulfilledWithValue({ data: null, message: data.message }));
    yield put(getPremintDetails({data:action?.payload?.getPremintData }));
  } catch (error) {
    const message =
      error.response.data.error || error.message || error.message.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.history);
  }
}

// show and hide general premint asset---------------------------------------------------->
export function* updateGeneralPremintAssetShowHide(action) {
  try {
    yield put(setPending());
    const res = yield call(updateShowHidePremintAssetAPI, action.payload.Updatedata);
    yield put(
      setFulfilledupdateGeneralPremitnAssetShowAndHide({ message: res.message })
    );
    yield put(getPremintDetails({data: action?.payload?.data}));
  } catch (error) {
    const message =
      error?.response?.data?.error || error.message || error.message.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.history);
  }
}

//get Redeemed general premint asset---------------------------------------------------->
export function* getRedeemePremintAssetSaga(action) {
  try {
    // console.log(action.payload)
    yield put(setPending());
    const res = yield call(getRedeemePremintAssetAPI, action.payload.data);
    console.log(res)
    yield put( setRedeemedGeneralAsset({ data: res, message: res.message }));
  } catch (error) {
    const message =
      error?.response?.data?.error || error.message || error.message.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.history);
  }
}

//get Redeemed general premint asset---------------------------------------------------->
export function* getAllRedeemedPremintAssetSaga(action) {
  try {
    console.log(action.payload)
    yield put(setPendingDownloadCSV());
    const res = yield call(getAllRedeemedPremintAssetAPI, action.payload);
    console.log(res)
    yield put( setAllRedeemedGeneralAsset({ data: res, message: res.message }));
  } catch (error) {
    const message =
      error?.response?.data?.error || error.message || error.message.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action.payload.history);
  }
}

export function* watchCreateGeneralAsset() {
  yield takeEvery(CREATE_GENERAL_PREMINT_ASSET, createGeneralAssetSaga);
}
export function* watchUpdateGeneralAsset() {
  yield takeEvery(UPDATE_GENERAL_PREMINT_ASSET, updateGeneralPremintAssestSaga);
}
export function* watchUpdateGeneralAssetShowAndHide() {
  yield takeEvery(
    UPDATE_GENERAL_PREMINT_ASSEST_SHOW_AND_HIDE,
    updateGeneralPremintAssetShowHide
  );
}
export function* watchGetRedeemedGeneralAsset() {
  yield takeEvery(
    GET_REDEEMED_GENERAL_ASSET,
    getRedeemePremintAssetSaga
  );
}
export function* watchGetAllRedeemedGeneralAsset() {
  yield takeEvery(
    GET_ALL_REDEEMED_GENERAL_ASSET,
    getAllRedeemedPremintAssetSaga
  );
}

export default function* rootSaga() {
  yield all([fork(watchCreateGeneralAsset)]);
  yield all([fork(watchUpdateGeneralAsset)]);
  yield all([fork(watchUpdateGeneralAssetShowAndHide)]);
  yield all([fork(watchGetRedeemedGeneralAsset)]);
  yield all([fork(watchGetAllRedeemedGeneralAsset)]);
}
