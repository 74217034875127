import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_ASSET_AND_COLLECTION_COUNT, GET_ALL_IP_INFO, GET_USER } from '../actions';
import { getCountAPI, getAllIpInfoAPI, getUserInfoAPI } from './API';
import { setAssestAndCollectionCount, setPending, setRejected, setAllIpInfo, setUser } from './actions';
import JwtExpired from '../../utils/JwtExpired';

// login------------------------------------------------------->
export function* getAssestAndCollectionCountSaga(action) {
  try {
    yield put(setPending());
    const res = yield call(getCountAPI, action.payload);
    yield put(setAssestAndCollectionCount({ res }));
  } catch (error) {
    const message =
      error.response.data.error || error.message || error.message.toString();
    JwtExpired(message, action?.payload?.history)
  }
}

// login------------------------------------------------------->
export function* getAllIpInfoSaga(action) {
  try {
    yield put(setPending());
    const res = yield call(getAllIpInfoAPI, action.payload.pagination);
    console.log(res)
    yield put(setAllIpInfo({ res }));
  } catch (error) {
    const message =
      error.response.data.error || error.message || error.message.toString();
    yield put(setRejected({ message}));
    JwtExpired(message, action?.payload?.history)
  }
}

// login------------------------------------------------------->
export function* getUserInfoSaga(action) {
  try {
    yield put(setPending());
    const res = yield call(getUserInfoAPI, action.payload.userPagination);
    console.log(res)
    yield put(setUser({ res }));
  } catch (error) {
    const message =
      error?.response?.data?.error || error.message || error.message.toString();
    yield put(setRejected({ message }));
    JwtExpired(message, action?.payload?.history)
  }
}

export function* watchGetAssestAndCollectionCountSagaSaga() {
  yield takeEvery(GET_ASSET_AND_COLLECTION_COUNT, getAssestAndCollectionCountSaga);
}
export function* watchgetAllIpInfoSaga() {
  yield takeEvery(GET_ALL_IP_INFO, getAllIpInfoSaga);
}
export function* watchGetUserInfoSaga() {
  yield takeEvery(GET_USER, getUserInfoSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAssestAndCollectionCountSagaSaga),
    fork(watchgetAllIpInfoSaga),
    fork(watchGetUserInfoSaga),
  ]);
}
