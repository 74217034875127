import {
  SET_COLLECTIONS,
  SET_UPLOAD_IMAGE,
  SET_REJECT_UPLOAD_IMAGE,
  UPDATE_IN_COLLECTION_SUCCESSFULLY,
  UPDATE_FIAT_PAYMENT_SUCCESSFULLY,
  SET_FIAT_PAYMENT_DATA,
  COLLECTION_PENDING,
  COLLECTION_PAGINATION,
  // <===============common===========>
  SET_PENDING,
  SET_REJECTED,
  SET_DRAWER_VISIBLE,
  RESET_STATE,
} from '../actions';

const initialState = {
  isLoading: true,
  isSuccess: false,
  isError: false,
  isMessage: null,
  collections: [],
  updateCollectioSuccessfully: false,
  // updateFiatPaymentSuccessfully: false,
  drawerVisible: false,
  type: '',
  collectionUpdateData: {},
  image: null,
  thumbnail: null,
  imgLoading: false,
  data: null,
  details: null,
  collectionLoading: true,
  totalCount: null,
  pagination: { current: 1, pageSize: 10, },

  // access deneid============================>
  accessError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_COLLECTIONS:
      // console.log(payload)
      return {
        ...state,
        collections: payload.data.data,
        image: null,
        thumbnail: null,
        // collectionLoading: false,
        isLoading: false,
        totalCount: payload.data.totalCount,
        pagination: {...state.pagination, total: payload.data.totalCount}
      };
    case SET_FIAT_PAYMENT_DATA:
      // console.log(payload.data)
      return {
        ...state,
        // isLoading: false,
        // isSuccess: true,
        // isMessage: payload.message,
        details: payload.data
      };
    case SET_UPLOAD_IMAGE:
      return {
        ...state,
        image: action.payload.image,
        imgLoading: action.payload.imgLoading,
        thumbnail: action.payload?.image?.thumbnail,
      };
    case SET_REJECT_UPLOAD_IMAGE:
      // console.log(payload)
      return {
        ...state,
        image: action.payload.image,
        imgLoading: action.payload.imgLoading,
        thumbnail: action.payload?.image?.thumbnail,
        isError: true,
        isMessage: action.payload.message
      };
    case UPDATE_IN_COLLECTION_SUCCESSFULLY:
      return {
        ...state,
        // isLoading: false,
        isSuccess: true,
        isMessage: payload.message,
        updateCollectioSuccessfully: true,
        // drawerVisible: false,
      };
    case COLLECTION_PENDING:
      return { ...state, isLoading: true,  drawerVisible: false }
    case UPDATE_FIAT_PAYMENT_SUCCESSFULLY:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isMessage: payload.message,
        // updateFiatPaymentSuccessfully: true,
      }
    case COLLECTION_PAGINATION:
      // console.log(action.payload)
      return {
        ...state,
        pagination: action.payload,
        updateCollectioSuccessfully: true
      }
      // <=====================================common=============================================>
    case SET_PENDING:
      return { ...state, isLoading: true };
      case SET_REJECTED:
        // console.log(payload)
        const msg = payload.message
        if(msg === 'jwt expired' || msg === 'Your account access has been denied'){
          return {
            ...state,
            isLoading: false,
            isError: true,
            isMessage: payload.message,
            // drawerVisible: false
            accessError: true,
            // collectionLoading: false
          }
        } else {
          return {
            ...state,
            isLoading: false,
            isError: true,
            isMessage: payload.message,
            // collectionLoading: false
          };
        }
      case SET_DRAWER_VISIBLE:
        return {
          ...state,
          drawerVisible: payload.visible,
          type: payload.type,
          collectionUpdateData: payload,
          image: null,
          thumbnail: null,
      };
    case RESET_STATE:
      return {
        ...state,
        // isLoading: false,
        // collectionLoading: false,
        isSuccess: false,
        isError: false,
        isMessage: '',
        updateCollectioSuccessfully: false,
        // updateFiatPaymentSuccessfully: false,
        accessError: false,
      };
    default:
      return { ...state };
  }
}
