import {
  SET_FULFILLED_UPDATE_PASSWORD,
  SET_PENDING,
  SET_FULFILLED_WITH_VALUE,
  SET_REJECTED,
  SET_FULFILLED,
  SET_MODAL_VISIBLE,
  RESET_STATE,
  SET_INFURA_DETAILS,
  SET_EMAIL_DETAILS,
} from '../actions';

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  isVisible: false,
  isMessage: '',
  data: [],
  emailDetails: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_FULFILLED_UPDATE_PASSWORD:
      return {
        ...state,
        isLoading: true,
        isSuccess: true,
        isMessage: payload.message,
        isVisible: false,
      };
    case SET_PENDING:
      return { ...state, isLoading: true };
    case SET_FULFILLED:
      return { ...state, isLoading: false, isSuccess: true };
    case SET_FULFILLED_WITH_VALUE:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isMessage: payload.message,
        data: payload.data,
        isVisible: false,
      };
    case SET_INFURA_DETAILS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isMessage: payload.message,
        details: payload.data,
      };
    case SET_EMAIL_DETAILS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isMessage: payload.message,
        emailDetails: payload.data,
      };
    case SET_REJECTED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isMessage: payload.message,
      };
    case SET_MODAL_VISIBLE:
      return { ...state, isVisible: payload };
    case RESET_STATE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: false,
        isMessage: '',
      };
    default:
      return { ...state };
  }
}
