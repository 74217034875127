import { postRequest, getRequest } from "../../utils/request";
const url = 'api'

export const loginAPI = data => postRequest(`/${url}/auth/login`, data)

export const sendOtpAndforgetPasswordAPI = data => postRequest(`/${url}/auth/forgotPassword`, data)

export const verifyOTPAndForgetPasswordAPI = data => postRequest(`/${url}/auth/verifyOTP`, data)

export const resetPasswordAPI = data => postRequest(`/${url}/auth/resetPassword`, data)

export const getProfileAPI = data => getRequest(`/${url}/auth/getProfile`, data)
