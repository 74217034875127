import { openNotificationWithIcon } from './notification';

const JwtExpired = (message, history, success) => {
  // console.log(success);
  // console.log(history);
  if (
    message === 'jwt expired' ||
    message === 'Your account access has been denied' ||
    message === 'jwt malformed'
  ) {
    openNotificationWithIcon('error', 'Failed', message);
    localStorage.removeItem('NFT-Admin');
    localStorage.removeItem('role');
    history && history.push('/');
  } else {
    openNotificationWithIcon(
      success === 'success' ? 'success' : 'error',
      success === 'success' ? 'Success' : 'Failed',
      message
    );
  }
};

export default JwtExpired;

// const JwtExpired = (message, history) => {
//   return new Promise((resolve, reject) => {
//     if (
//       message === 'jwt expired' ||
//       message === 'Your account access has been denied'
//     ) {
//       // console.log(history);
//       // func()
//       // openNotificationWithIcon('error', 'Failed1', message);
//       localStorage.removeItem('NFTPlatformAdmin');
//       history.push('/');
//       reject( new Error(message))
//     }
//   });
// };

// export default JwtExpired;
