/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
// export const TODO_GET_LIST = 'TODO_GET_LIST';
// export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
// export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
// export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
// export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
// export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
// export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
// export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
// export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
// export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

export * from './menu/actions';
export * from './settings/actions';
// export * from './auth/actions';
// export * from './todo/actions';
// export * from './chat/actions';
// export * from './surveyList/actions';
// export * from './surveyDetail/actions';
export * from './PremintAssests/aciotns';
export * from './collection/action';


//SET STATE
export const SET_STATE = "SET_STATE"
export const CLEAR_STATE = "CLEAR_STATE"

// Premint Assest
export const GET_PREMINT_ASSEST = "GET_PREMINT_ASSEST"
export const SET_PREMINT_ASSEST = "SET_PREMINT_ASSEST"
export const CREATE_PREMINT_ASSEST = "CREATE_PREMINT_ASSEST"
export const UPDATE_PREMINT_ASSEST = "UPDATE_PREMINT_ASSEST"
export const SET_CREATE_PREMINT_ASSEST = "SET_CREATE_PREMINT_ASSEST"
export const SET_LOADING_CREATE_PREMINT_ASSEST = "SET_LOADING_CREATE_PREMINT_ASSEST"
export const GET_PREMINT_ASSEST_COUNT = "GET_PREMINT_ASSEST_COUNT"
export const SET_PREMINT_ASSEST_COUNT = "SET_PREMINT_ASSEST_COUNT"
export const CREATE_ASSEST_FAILED = "CREATE_ASSEST_FAILED"
export const CLEAR_FIELD = "CLEAR_FIELD"

// Upload Image
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const SET_UPLOAD_IMAGE = 'SET_UPLOAD_IMAGE'
export const SET_REJECT_UPLOAD_IMAGE = 'SET_REJECT_UPLOAD_IMAGE'

//Collection
export const GET_COLLECTIONS = "GET_COLLECTIONS"
export const SET_COLLECTIONS = 'SET_COLLECTIONS'
export const SET_COLLECTIONS_STATE = "SET_COLLECTIONS_STATE"
export const UPDATE_COLLECTIONS_VISIBLE = 'UPDATE_COLLECTIONS_VISIBLE'
export const CREATE_COLLECTION = 'CREATE_COLLECTION'
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION'
export const UPDATE_FAIT_PAYMENT_IN_COLLECTION = 'UPDATE_FAIT_PAYMENT_IN_COLLECTION'
export const UPDATE_IN_COLLECTION_SUCCESSFULLY = 'UPDATE_IN_COLLECTION_SUCCESSFULLY'
export const GET_FAIT_PAYMENT_IN_COLLECTION = 'GET_FAIT_PAYMENT_IN_COLLECTION'
export const SET_FIAT_PAYMENT_DATA = 'SET_FIAT_PAYMENT_DATA'
export const COLLECTION_PENDING = 'COLLECTION_PENDING'
export const COLLECTION_PAGINATION = 'COLLECTION_PAGINATION'
export const UPDATE_FIAT_PAYMENT_SUCCESSFULLY = 'UPDATE_FIAT_PAYMENT_SUCCESSFULLY'
export const GET_USER = 'GET_USER'
export const SET_USER = 'SET_USER'
export const USER_PAGINATION = 'USER_PAGINATION'


//Get Premnit Assest from collection
export const GET_PREMINT_ASSEST_BY_COLLECTION_ID = 'GET_PREMINT_ASSEST_BY_COLLECTION_ID'
export const UPLOAD_PREMINT_IMAGE = 'UPLOAD_PREMINT_IMAGE'
export const SET_UPLOAD_PREMINT_IMAGE = 'SET_UPLOAD_PREMINT_IMAGE'
export const GET_PREMINT_ASSEST_BY_COLLECTION_ID_FAILED = 'SET_PREMINT_ASSEST_BY_COLLECTION_ID'
export const SET_PREMINT_ASSEST_BY_COLLECTION_ID = 'SET_PREMINT_ASSEST_BY_COLLECTION_ID'
export const SET_PREMINT_DRAWER_VISIBLE = 'SET_PREMINT_DRAWER_VISIBLE'
export const UPDATE_PREMINT_ASSEST_SHOW_AND_HIDE = 'UPDATE_PREMINT_ASSEST_SHOW_AND_HIDE'
export const SET_FULFILLED_SHOW_HIDE_PREMINT_ASSET = 'SET_FULFILLED_SHOW_HIDE_PREMINT_ASSET'
export const SET_FULFILLED_GET_TABLE_DATA = 'SET_FULFILLED_GET_TABLE_DATA'
export const SET_FULFILLED_CREATE_PREMINT_ASSET = 'SET_FULFILLED_CREATE_PREMINT_ASSET'
export const CREATE_PREMINT_ASSET_FROM_JSON = 'CREATE_PREMINT_ASSET_FROM_JSON'
export const REGISTER_COLLECTION_SMART_CONTRACT = 'REGISTER_COLLECTION_SMART_CONTRACT'
export const GET_COLLECTION_SMART_CONTRACT_BY_ID = 'GET_COLLECTION_SMART_CONTRACT_BY_ID'
export const SET_FULFILLED_GET_COLLECTION_SMART_CONTRACT_BY_ID = 'SET_FULFILLED_GET_COLLECTION_SMART_CONTRACT_BY_ID'
export const UPDATE_COLLECTION_SMART_CONTRACT = 'UPDATE_COLLECTION_SMART_CONTRACT'
export const AIRDROP_COLLECTION_SMART_CONTRACT = 'AIRDROP_COLLECTION_SMART_CONTRACT'
export const SMART_CONTRACT_PENDING_STATE = 'SMART_CONTRACT_PENDING_STATE'
export const SET_CONTRACT_SUCCESS_STATE = 'SET_CONTRACT_SUCCESS_STATE'
export const SET_CONTRACT_SUCCESS_STATE_FOR_AIRDROP_AND_MERKLE_ROOT = 'SET_CONTRACT_SUCCESS_STATE_FOR_AIRDROP_AND_MERKLE_ROOT'
export const SET_CHECK = 'SET_CHECK'
export const SET_PREMINT_MODAL_VISIBLE = "SET_PREMINT_MODAL_VISIBLE"
export const SET_PREMINT_PENDING = "SET_PREMINT_PENDING"
export const SET_CREATE_ASSEST_JSON_SUCCESS = "SET_CREATE_ASSEST_JSON_SUCCESS"
export const PREMINT_PAGINATION = "PREMINT_PAGINATION"
// export const SET_FULFILLED_CREATE_PREMINT_ASSET_FROM_JSON = 'SET_FULFILLED_GET_TABLE_DATA'

// <===========================manual==========================>
export const CREATE_MANUAL_PREMINT_ASSET= 'CREATE_MANUAL_PREMINT_ASSET'
export const UPDATE_MANUAL_PREMINT_ASSET= 'UPDATE_MANUAL_PREMINT_ASSET'
export const PUBLISH_MANUAL_PREMINT_ASSETS = 'PUBLISH_MANUAL_PREMINT_ASSETS'
export const FULFILLED_PUBLISH_MANUAL_PREMINT_ASSETS = 'FULFILLED_PUBLISH_MANUAL_PREMINT_ASSETS'
export const REJECTED_PUBLISH_MANUAL_PREMINT_ASSETS = 'REJECTED_PUBLISH_MANUAL_PREMINT_ASSETS'
export const SET_MANUAL_PREMINT_ASSEST_SUCCES = 'SET_MANUAL_PREMINT_ASSEST_SUCCES'

// <------------------------------solana----------------------->
export const CREATE_SOLANA_PREMINT_ASSET = 'CREATE_SOLANA_PREMINT_ASSET'
export const GET_SOLANA_PREMINT_ASSET = 'GET_SOLANA_PREMINT_ASSET'
export const SET_SOLANA_PREMINT_ASSET = 'SET_SOLANA_PREMINT_ASSET'
export const UPLOAD_SOLANA_IMGAE_TO_AWS = 'UPLOAD_SOLANA_IMGAE_TO_AWS'
export const SET_SOLANA_IMGAE_TO_AWS = 'SET_SOLANA_IMGAE_TO_AWS'

// <------------------------------general----------------------->
export const CREATE_GENERAL_PREMINT_ASSET = 'CREATE_GENERAL_PREMINT_ASSET'
export const UPDATE_GENERAL_PREMINT_ASSET = 'UPDATE_GENERAL_PREMINT_ASSET'
export const UPDATE_GENERAL_PREMINT_ASSEST_SHOW_AND_HIDE = 'UPDATE_GENERAL_PREMINT_ASSEST_SHOW_AND_HIDE'
export const SET_FULFILLED_SHOW_HIDE_GENERAL_PREMINT_ASSET = 'SET_FULFILLED_SHOW_HIDE_GENERAL_PREMINT_ASSET'
export const PAGINAION_GENERAL_ASSET = 'PAGINAION_GENERAL_ASSET'
//<-------------------------- redemmed general asset------------------------------------->
export const GET_REDEEMED_GENERAL_ASSET = 'GET_REDEEMED_GENERAL_ASSET'
export const SET_REDEEMED_GENERAL_ASSET = 'SET_REDEEMED_GENERAL_ASSET'
export const SET_PENDING_CSV_DOWNLOAD = 'SET_PENDING_CSV_DOWNLOAD'
export const GET_ALL_REDEEMED_GENERAL_ASSET = 'GET_ALL_REDEEMED_GENERAL_ASSET'
export const SET_ALL_REDEEMED_GENERAL_ASSET = 'SET_ALL_REDEEMED_GENERAL_ASSET'

// <=====================payment=========================>
export const SEND_PAYMENT_DETAILS = 'SEND_PAYMENT_DETAILS'
export const GETISENABLE_PAYMENT = 'GETISENABLE_PAYMENT'
export const SETISENABLE_PAYMENT = 'SETISENABLE_PAYMENT'
export const ENABLE_PAYMENT = 'ENABLE_PAYMENT'
export const GET_FIAT_PAYMENT = 'GET_FIAT_PAYMENT'
export const SET_FIAT_PAYMENT = 'SET_FIAT_PAYMENT'
export const SET_FIAT_PAYMENT_STATE_UPDATED = 'SET_FIAT_PAYMENT_STATE_UPDATED'

// <===================Admin Login=================================>
export const LOGIN_PLAFTORM_ADMIN = 'LOGIN_PLAFTORM_ADMIN'
export const SET_FULFILLED_LOGIN = 'SET_FULFILLED_LOGIN'
export const FORGET_PASSWORD = 'FORGET_PASSWORD'
export const SET_PASSWORD_STATE = 'SET_PASSWORD_STATE'
export const VERIFY_OTP = 'VERIFY_OTP'
export const VERIFIED_OTP_AND_RESET_PASSWORD = 'VERIFIED_OTP_AND_RESET_PASSWORD'
export const SET_FORM_STATE = 'SET_FORM_STATE'
export const GET_PROFILE = 'GET_PROFILE'
export const SET_PROFILE_SUCCESSFULLY = 'SET_PROFILE_SUCCESSFULLY'
export const LOGIN_PENDING_STATE = 'LOGIN_PEDING_STATE'
export const SET_OTP_PENDING = 'SET_OTP_PENDING'
export const SET_TIMER = 'SET_TIMER'
// export const SET_TIMMING = 'SET_TIMMING'

// <===========================Setting========================>
export const ADD_FRONTED_URL = 'ADD_FRONTED_URL'
export const UPDATE_FRONTED_URL = 'UPDATE_FRONTED_URL'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const SET_FULFILLED_UPDATE_PASSWORD = 'SET_FULFILLED_UPDATE_PASSWORD'
export const GET_INFURA_URL = 'GET_INFURA_URL'
export const ADD_INFURA_URL = 'ADD_INFURA_URL'
export const UPDATE_INFURA_URL = 'UPDATE_INFURA_URL'
export const SET_INFURA_DETAILS = 'SET_INFURA_DETAILS'
export const GET_EMAIL = 'GET_EMAIL'
export const ADD_EMAIL = 'ADD_EMAIL'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const SET_EMAIL_DETAILS = 'SET_EMAIL_DETAILS'

// // //=========================DASHBOARD=======================>
export const GET_ASSET_AND_COLLECTION_COUNT = "GET_ASSET_AND_COLLECTION_COUNT"
export const SET_ASSET_AND_COLLECTION_COUNT = "SET_ASSET_AND_COLLECTION_COUNT"
export const SET_PENDING_DASHBOARD = 'SET_PENDING_DASHBOARD '
export const SET_REJECTED_DASHBOARD  = 'SET_REJECTED_DASHBOARD '
export const RESET_STATE_DASHBOARD  = 'RESET_STATE_DASHBOARD'
export const GET_ALL_IP_INFO  = 'GET_ALL_IP_INFO'
export const SET_ALL_IP_INFO  = 'SET_ALL_IP_INFO'
export const IP_INFO_PAGINATION  = 'IP_INFO_PAGINATION'

// common
export const SET_DATA = "SET_DATA"
export const SET_DRAWER_VISIBLE = "SET_DRAWER_VISIBLE"
export const SET_MODAL_VISIBLE = "SET_MODAL_VISIBLE"
export const SET_NESTING_MODAL_VISIBLE = "SET_NESTING_MODAL_VISIBLE"
export const SET_PENDING = 'SET_PENDING'
export const SET_FULFILLED = 'SET_FULFILLED'
export const SET_FULFILLED_WITH_VALUE = 'SET_FULFILLED_WITH_VALUE'
export const SET_REJECTED = 'SET_REJECTED'
export const RESET_STATE = 'RESET_STATE'